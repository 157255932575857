import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import profile from './components/profile.vue'
import register from './components/register.vue'
import dash from './components/dash.vue'
import info from './components/info.vue'
import messages from './components/messages.vue'
import changepassword from './components/changepassword.vue'
import holiday from './components/holiday.vue'
import disease from './components/disease.vue'
import filterusers from './components/filterusers.vue'
import users from './components/users.vue'
import editUser from './components/editUser.vue'
import changepasswordadmin from './components/changepasswordadmin.vue'
import settings from './components/settings.vue'
import pianoOrario from './components/pianoOrario.vue'
import editPianoOrario from './components/editPianoOrario.vue'
import copyPianoOrario from './components/copyPianoOrario.vue'
import risorse from './components/risorse.vue'
import editRisorsa from './components/editRisorsa.vue'
import filterRisorse from './components/filterRisorse.vue'
import company from './components/company.vue'
import editCompany from './components/editCompany.vue'
import filterPianoOrario from './components/filterPianoOrario.vue'
import companyBasic from './components/companyBasic.vue'
import editCompanyBasic from './components/editCompanyBasic.vue'
import assocUserCompany from './components/assocUserCompany.vue'
import assocUserCompanyListCmp from './components/assocUserCompanyListCmp.vue'
import holidayHistory from './components/holidayHistory.vue'
import diseaseHistory from './components/diseaseHistory.vue'
import editDisease from './components/editDisease.vue'
import filterGroups from './components/filterGroups.vue'
import groups from './components/groups.vue'
import editGroup from './components/editGroup.vue'
import editGroupSelUsers from './components/editGroupSelUsers.vue'
import editGroupMails from './components/editGroupMails.vue'
import editGroupEditMail from './components/editGroupEditMail.vue'
import editGroupMailsRefresh from './components/editGroupMailsRefresh.vue'
import filterHistoryDisease from './components/filterHistoryDisease.vue'
import filterHolidayHistory from './components/filterHolidayHistory.vue'
import changepasswordinitialcustsup from './components/changepasswordinitialcustsup.vue'
import changepasswordinitial from './components/changepasswordinitial.vue'
import ricercaCandidati from './components/ricercaCandidati.vue'
import editRicercaCandidati from './components/editRicercaCandidati.vue'
import storicoClientiProposti from './components/storicoClientiProposti.vue'
import editStoricoClientiProposti from './components/editStoricoClientiProposti.vue'
import copyStoricoClientiProposti from './components/copyStoricoClientiProposti.vue'
import annunci from './components/annunci.vue'
import editAnnuncio from './components/editAnnuncio.vue'
import referenti from './components/referenti.vue'
import filterReferenti from './components/filterReferenti.vue'
import editReferente from './components/editReferente.vue'
import copyReferente from './components/copyReferente.vue'
import CvRicevuti from './components/CvRicevuti.vue'
import AdsCompany from './components/AdsCompany.vue'
import StatiIterColloquio from './components/StatiIterColloquio.vue'
import editStatiIterColloquio from './components/editStatiIterColloquio.vue'
import regioni from './components/regioni.vue'
import editRegione from './components/editRegione.vue'
import province from './components/province.vue'
import editProvincia from './components/editProvincia.vue'
import comuni from './components/comuni.vue'
import editComune from './components/editComune.vue'
import studi from './components/studi.vue'
import editStudio from './components/editStudio.vue'
import tipiContratti from './components/tipiContratti.vue'
import editTipoContratto from './components/editTipoContratto.vue'
import giornataLavoroTipo from './components/giornataLavoroTipo.vue'
import editGiornataLavoroTipo from './components/editGiornataLavoroTipo.vue'
import privacy from './components/privacy.vue'
import editPrivacy from './components/editPrivacy.vue'
import confInvioMail from './components/confInvioMail.vue'
import mailGruppo from './components/mailGruppo.vue'
import editMailGruppo from './components/editMailGruppo.vue'
import categorie from './components/categorie.vue'
import editCategoria from './components/editCategoria.vue'
import annunciCandidati from './components/annunciCandidati.vue'
import candidati from './components/candidati.vue'
import editCandidato from './components/editCandidato.vue'
import sottocategorie from './components/sottocategorie.vue'
import editSottocategoria from './components/editSottocategoria.vue'
import settori from './components/settori.vue'
import editSettore from './components/editSettore.vue'
import skills from './components/skills.vue'
import editSkill from './components/editSkill.vue'
import searchAds from './components/searchAds.vue'
import settingsPortale from './components/settingsPortale.vue'
import adsDetail from './components/adsDetail.vue'
import initSearchAds from './components/initSearchAds.vue'
import impostaLogo from './components/impostaLogo.vue'
import imagesSfondo from './components/imagesSfondo.vue'
import confAzienda from './components/confAzienda.vue'
import configRss from './components/configRss.vue'
import configCreatedBy from './components/configCreatedBy.vue'
import configPrivacy from './components/configPrivacy.vue'
import configTermOfUse from './components/configTermOfUse.vue'
import getStartToday from './components/getStartToday.vue'
import adsGrid from './components/adsGrid.vue'
import initAllJobs from './components/initAllJobs.vue'
import reloadPage from './components/reloadPage.vue'
import initAdsGrid from './components/initAdsGrid.vue'
import goToAds from './components/goToAds.vue'
import loginCandidati from './components/loginCandidati.vue'
import CvCandidato from './components/CvCandidato.vue'
import loginCandidatiJobmule from './components/loginCandidatiJobmule.vue'
import loginAziendaJobmule from './components/loginAziendaJobmule.vue'
import loginAzienda from './components/loginAzienda.vue'
import registerCompany from './components/registerCompany.vue'
import categorieSkills from './components/categorieSkills.vue'
import editCategoriaSkill from './components/editCategoriaSkill.vue'
import reloadPageMenu from './components/reloadPageMenu.vue'
import initProfile from './components/initProfile.vue'
import profileCandidato from './components/profileCandidato.vue'
import initGroups from './components/initGroups.vue'
import groupsAzienda from './components/groupsAzienda.vue'
import editGroupAzienda from './components/editGroupAzienda.vue'
import editGroupMailsAzienda from './components/editGroupMailsAzienda.vue'
import editGroupEditMailAzienda from './components/editGroupEditMailAzienda.vue'
import editGroupMailsRefreshAzienda from './components/editGroupMailsRefreshAzienda.vue'
import groupsAdmin from './components/groupsAdmin.vue'
import filterGroupsAdmin from './components/filterGroupsAdmin.vue'
import editGroupAdmin from './components/editGroupAdmin.vue'
import initCompany from './components/initCompany.vue'
import companyAdmin from './components/companyAdmin.vue'
import editCompanyAdmin from './components/editCompanyAdmin.vue'
import editGroupMailsAdmin from './components/editGroupMailsAdmin.vue'
import editGroupEditMailAdmin from './components/editGroupEditMailAdmin.vue'
import testCarousel from './components/testCarousel.vue'
import newsletter from './components/newsletter.vue'
import confInvioNewsletter from './components/confInvioNewsletter.vue'
import configPrivacyHome from './components/configPrivacyHome.vue'
import privacyHome from './components/privacyHome.vue'
import confTitleHeader from './components/confTitleHeader.vue'
import configurationProducts from './components/configurationProducts.vue'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Login
        },

        {
            path: '/dash',
            name: 'dash',
            component: dash
        },


     

        {
            path: '/profile',
            name: 'profile',
            component: profile
        },

        {
            path: '/register',
            name: 'register',
            component: register
        },

        {
            path: '/category/modfiy-account',
            name: 'profile',
            component: profile
        }
      
        ,
        {
            path: '/info',
            name: 'info',
            component: info
        }

        ,
        {
            path: '/changepassword',
            name: 'changepassword',
            component: changepassword
        }

        ,
        {
            path: '/messages',
            name: 'messages',
            component: messages
        }


        ,
        {
            path: '/holiday',
            name: 'holiday',
            component: holiday
        }
        ,
        {
            path: '/disease',
            name: 'disease',
            component: disease
        }

        ,
        {
            path: '/filterusers',
            name: 'filterusers',
            component: filterusers
        }


        ,
        {
            path: '/users',
            name: 'users',
            component: users
        }

        ,
        {
            path: '/editUser/:id',
            name: 'editUser',
            component: editUser,
            props: true
        }

        ,

        {
            path: '/changepassword/:id',
            name: 'changepassword',
            component: changepasswordadmin,
            props: true
        },

        {
            path: '/settings',
            name: 'settings',
            component: settings
        },


        {
            path: '/pianoOrario',
            name: 'pianoOrario',
            component: pianoOrario
        }

        ,
        {
            path: '/editPianoOrario/:id',
            name: 'editPianoOrario',
            component: editPianoOrario,
            props: true
        }

        ,
        {
            path: '/copyPianoOrario/:id',
            name: 'copyPianoOrario',
            component: copyPianoOrario,
            props: true
        },


        {
            path: '/risorse',
            name: 'risorse',
            component: risorse
        }

        ,
        {
            path: '/editRisorsa/:id',
            name: 'editRisorsa',
            component: editRisorsa,
            props: true
        },


        {
            path: '/filterRisorse',
            name: 'filterRisorse',
            component: filterRisorse,
            props: true
        },


        {
            path: '/company',
            name: 'company',
            component: company,
            props: true
        },


        {
            path: '/editCompany/:id',
            name: 'editCompany',
            component: editCompany,
            props: true
        },


        {
            path: '/filterPianoOrario',
            name: 'filterPianoOrario',
            component: filterPianoOrario,
            props: true
        },


        {
            path: '/companyBasic',
            name: 'companyBasic',
            component: companyBasic,
            props: true
        },


        {
            path: '/editCompanyBasic/:id',
            name: 'editCompanyBasic',
            component: editCompanyBasic,
            props: true
        },

        {
            path: '/assocUserCompany',
            name: 'assocUserCompany',
            component: assocUserCompany,
            props: true
        },

        {
            path: '/assocUserCompanyListCmp',
            name: 'assocUserCompanyListCmp',
            component: assocUserCompanyListCmp,
            props: true
        },

        {
            path: '/holidayHistory',
            name: 'holidayHistory',
            component: holidayHistory,
            props: true
        },

        {
            path: '/diseaseHistory',
            name: 'diseaseHistory',
            component: diseaseHistory,
            props: true
        },

        {
            path: '/editDisease/:id',
            name: 'editDisease',
            component: editDisease,
            props: true
        },


        {
            path: '/filterGroups',
            name: 'filterGroups',
            component: filterGroups,
            props: true
        },

        {
            path: '/groups',
            name: 'groups',
            component: groups,
            props: true
        },


        {
            path: '/editGroup/:id',
            name: 'editGroup',
            component: editGroup,
            props: true
        },

        {
            path: '/editGroupSelUsers',
            name: 'editGroupSelUsers',
            component: editGroupSelUsers,
            props: true
        },

        {
            path: '/editGroupMails',
            name: 'editGroupMails',
            component: editGroupMails,
            props: true
        },

        {
            path: '/editGroupEditMail/:id',
            name: 'editGroupEditMail',
            component: editGroupEditMail,
            props: true
        },

        {
            path: '/editGroupMailsRefresh',
            name: 'editGroupMailsRefresh',
            component: editGroupMailsRefresh,
            props: true
        },

        {
            path: '/filterHistoryDisease',
            name: 'filterHistoryDisease',
            component: filterHistoryDisease,
            props: true
        },

        {
            path: '/filterHolidayHistory',
            name: 'filterHolidayHistory',
            component: filterHolidayHistory,
            props: true
        },


      
        {
            path: '/changepasswordinitialcustsup',
            name: 'changepasswordinitialcustsup',
            component: changepasswordinitialcustsup,
            props: true
        },



        {
            path: '/changepasswordinitial',
            name: 'changepasswordinitial',
            component: changepasswordinitial,
            props: true
        },

        {
            path: '/ricercaCandidati',
            name: 'ricercaCandidati',
            component: ricercaCandidati,
            props: true
        },

        {
            path: '/editRicercaCandidati/:id',
            name: 'editRicercaCandidati',
            component: editRicercaCandidati,
            props: true
        },

        {
            path: '/storicoClientiProposti',
            name: 'storicoClientiProposti',
            component: storicoClientiProposti,
            props: true
        },

        {
            path: '/editStoricoClientiProposti/:id',
            name: 'editStoricoClientiProposti',
            component: editStoricoClientiProposti,
            props: true
        },

        {
            path: '/copyStoricoClientiProposti/:id',
            name: 'copyStoricoClientiProposti',
            component: copyStoricoClientiProposti,
            props: true
        },

        {
            path: '/annunci',
            name: 'annunci',
            component: annunci,
            props: true
        },


        {
            path: '/editAnnuncio/:id',
            name: 'editAnnuncio',
            component: editAnnuncio,
            props: true
        },


        {
            path: '/referenti',
            name: 'referenti',
            component: referenti,
            props: true
        },

        {
            path: '/filterReferenti',
            name: 'filterReferenti',
            component: filterReferenti,
            props: true
        },

        {
            path: '/editReferente/:id',
            name: 'editReferente',
            component: editReferente,
            props: true
        },

        {
            path: '/copyReferente/:id',
            name: 'copyReferente',
            component: copyReferente,
            props: true
        },

        {
            path: '/CvRicevuti',
            name: 'CvRicevuti',
            component: CvRicevuti,
            props: true
        },

        {
            path: '/AdsCompany',
            name: 'AdsCompany',
            component: AdsCompany,
            props: true
        },


        {
            path: '/StatiIterColloquio',
            name: 'StatiIterColloquio',
            component: StatiIterColloquio,
            props: true
        },

        {
            path: '/editStatiIterColloquio/:id',
            name: 'editStatiIterColloquio',
            component: editStatiIterColloquio,
            props: true
        },

        {
            path: '/regioni',
            name: 'regioni',
            component: regioni,
            props: true
        },

        {
            path: '/editRegione/:id',
            name: 'editRegione',
            component: editRegione,
            props: true
        },

        {
            path: '/province',
            name: 'province',
            component: province,
            props: true
        },

        {
            path: '/editProvincia/:id',
            name: 'editProvincia',
            component: editProvincia,
            props: true
        },

        {
            path: '/comuni',
            name: 'comuni',
            component: comuni,
            props: true
        },

        {
            path: '/editComune/:id',
            name: 'editComune',
            component: editComune,
            props: true
        },

        {
            path: '/studi',
            name: 'studi',
            component: studi,
            props: true
        },

        {
            path: '/editStudio/:id',
            name: 'editStudio',
            component: editStudio,
            props: true
        },

        {
            path: '/tipiContratti',
            name: 'tipiContratti',
            component: tipiContratti,
            props: true
        },

        {
            path: '/editTipoContratto/:id',
            name: 'editTipoContratto',
            component: editTipoContratto,
            props: true
        },

        {
            path: '/giornataLavoroTipo',
            name: 'giornataLavoroTipo',
            component: giornataLavoroTipo,
            props: true
        },

        {
            path: '/editGiornataLavoroTipo/:id',
            name: 'editGiornataLavoroTipo',
            component: editGiornataLavoroTipo,
            props: true
        },

        {
            path: '/privacy',
            name: 'privacy',
            component: privacy,
            props: true
        },

        {
            path: '/editPrivacy/:id',
            name: 'editPrivacy',
            component: editPrivacy,
            props: true
        },

        {
            path: '/confInvioMail',
            name: 'confInvioMail',
            component: confInvioMail,
            props: true
        },

        {
            path: '/mailGruppo',
            name: 'mailGruppo',
            component: mailGruppo,
            props: true
        },

        {
            path: '/editMailGruppo/:id',
            name: 'editMailGruppo',
            component: editMailGruppo,
            props: true
        },

        {
            path: '/categorie',
            name: 'categorie',
            component: categorie,
            props: true
        },

        {
            path: '/editCategoria/:id',
            name: 'editCategoria',
            component: editCategoria,
            props: true
        },

        {
            path: '/annunciCandidati/:id',
            name: 'annunciCandidati',
            component: annunciCandidati,
            props: true
        },

        {
            path: '/candidati',
            name: 'candidati',
            component: candidati,
            props: true
        },

        {
            path: '/editCandidato/:id',
            name: 'editCandidato',
            component: editCandidato,
            props: true
        },

        {
            path: '/sottocategorie',
            name: 'sottocategorie',
            component: sottocategorie,
            props: true
        },


        {
            path: '/editSottocategoria/:id',
            name: 'editSottocategoria',
            component: editSottocategoria,
            props: true
        },


        {
            path: '/settori',
            name: 'settori',
            component: settori,
            props: true
        },


        {
            path: '/editSettore/:id',
            name: 'editSettore',
            component: editSettore,
            props: true
        },

        {
            path: '/skills',
            name: 'skills',
            component: skills,
            props: true
        },

        {
            path: '/editSkill/:id',
            name: 'editSkill',
            component: editSkill,
            props: true
        },

     
        {
            path: '/searchAds',
            name: 'searchAds',
            component: searchAds
        },

        {
            path: '/settingsPortale',
            name: 'settingsPortale',
            component: settingsPortale
        },


        {
            path: '/adsDetail',
            name: 'adsDetail',
            component: adsDetail,
            props: true
        },

        {
            path: '/initSearchAds',
            name: 'initSearchAds',
            component: initSearchAds
        },

        {
            path: '/impostaLogo',
            name: 'impostaLogo',
            component: impostaLogo
        },

        {
            path: '/imagesSfondo',
            name: 'imagesSfondo',
            component: imagesSfondo
        },

        {
            path: '/confAzienda',
            name: 'confAzienda',
            component: confAzienda
        },

        {
            path: '/configRss',
            name: 'configRss',
            component: configRss
        },

        {
            path: '/configCreatedBy',
            name: 'configCreatedBy',
            component: configCreatedBy
        },

        {
            path: '/configPrivacy',
            name: 'configPrivacy',
            component: configPrivacy
        },

        {
            path: '/configTermOfUse',
            name: 'configTermOfUse',
            component: configTermOfUse
        },

        {
            path: '/getStartToday',
            name: 'getStartToday',
            component: getStartToday
        },

        {
            path: '/adsGrid',
            name: 'adsGrid',
            component: adsGrid
        },

        {
            path: '/initAllJobs',
            name: 'initAllJobs',
            component: initAllJobs
        },

        {
            path: '/reloadPage/:id',
            name: 'reloadPage',
            component: reloadPage
        },

        {
            path: '/initAdsGrid',
            name: 'initAdsGrid',
            component: initAdsGrid
        },


        {
            path: '/goToAds/:id',
            name: 'goToAds',
            component: goToAds
        },

        {
            path: '/loginCandidati',
            name: 'loginCandidati',
            component: loginCandidati
        },

        {
            path: '/CvCandidato',
            name: 'CvCandidato',
            component: CvCandidato
        },

        {
            path: '/loginCandidatiJobmule',
            name: 'loginCandidatiJobmule',
            component: loginCandidatiJobmule
        },

        {
            path: '/loginAziendaJobmule',
            name: 'loginAziendaJobmule',
            component: loginAziendaJobmule
        },

        {
            path: '/loginAzienda',
            name: 'loginAzienda',
            component: loginAzienda
        },
        
        {
            path: '/registerCompany',
            name: 'registerCompany',
            component: registerCompany
        },

        {
            path: '/categorieSkills',
            name: 'categorieSkills',
            component: categorieSkills,
            props: true
        },

        {
            path: '/editCategoriaSkill/:id',
            name: 'editCategoriaSkill',
            component: editCategoriaSkill,
            props: true
        },

        {
            path: '/reloadPageMenu',
            name: 'reloadPageMenu',
            component: reloadPageMenu,
            props: true
        },

        {
            path: '/initProfile',
            name: 'initProfile',
            component: initProfile,
            props: true
        },

        {
            path: '/profileCandidato',
            name: 'profileCandidato',
            component: profileCandidato,
            props: true
        },

        {
            path: '/initGroups',
            name: 'initGroups',
            component: initGroups,
            props: true
        },

        {
            path: '/groupsAzienda',
            name: 'groupsAzienda',
            component: groupsAzienda,
            props: true
        },

        {
            path: '/editGroupAzienda/:id',
            name: 'editGroupAzienda',
            component: editGroupAzienda,
            props: true
        },

        {
            path: '/editGroupMailsAzienda',
            name: 'editGroupMailsAzienda',
            component: editGroupMailsAzienda,
            props: true
        },

        {
            path: '/editGroupEditMailAzienda/:id',
            name: 'editGroupEditMailAzienda',
            component: editGroupEditMailAzienda,
            props: true
        },


        {
            path: '/editGroupMailsRefreshAzienda',
            name: 'editGroupMailsRefreshAzienda',
            component: editGroupMailsRefreshAzienda,
            props: true
        },

        {
            path: '/groupsAdmin',
            name: 'groupsAdmin',
            component: groupsAdmin,
            props: true
        },

        {
            path: '/filterGroupsAdmin',
            name: 'filterGroupsAdmin',
            component: filterGroupsAdmin,
            props: true
        },


        {
            path: '/editGroupAdmin/:id',
            name: 'editGroupAdmin',
            component: editGroupAdmin,
            props: true
        },

        {
            path: '/initCompany',
            name: 'initCompany',
            component: initCompany,
            props: true
        },

        {
            path: '/companyAdmin',
            name: 'companyAdmin',
            component: companyAdmin,
            props: true
        },


        {
            path: '/editCompanyAdmin/:id',
            name: 'editCompanyAdmin',
            component: editCompanyAdmin,
            props: true
        },

        {
            path: '/editGroupMailsAdmin',
            name: 'editGroupMailsAdmin',
            component: editGroupMailsAdmin,
            props: true
        },

        {
            path: '/editGroupEditMailAdmin/:id',
            name: 'editGroupEditMailAdmin',
            component: editGroupEditMailAdmin,
            props: true
        },

        {
            path: '/testCarousel',
            name: 'testCarousel',
            component: testCarousel,
            props: true
        },

        {
            path: '/newsletter',
            name: 'newsletter',
            component: newsletter,
            props: true
        },

        {
            path: '/confInvioNewsletter',
            name: 'confInvioNewsletter',
            component: confInvioNewsletter,
            props: true
        },

        {
            path: '/configPrivacyHome',
            name: 'configPrivacyHome',
            component: configPrivacyHome,
            props: true
        },

        {
            path: '/privacyHome',
            name: 'privacyHome',
            component: privacyHome,
            props: true
        },

        {
            path: '/confTitleHeader',
            name: 'confTitleHeader',
            component: confTitleHeader,
            props: true
        },


        {
            path: '/configurationProducts',
            name: 'configurationProducts',
            component: configurationProducts,
            props: true
        },


    ]
})


router.afterEach((to, from) => {
    console.log("from", from);
    console.log("going to " + to.fullPath)

    // console.log(router.app.$refs);


    /*         console.log(to);
    
            console.log("Current Refs");
            console.log(router.app.$refs);
    
            Vue.nextTick(function () {
                console.log("after NextTick");
                console.log(router.app.$refs);
            }) */
})


export default router
