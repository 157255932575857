<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center editProfile"
        style="min-width:280px;">

        <v-form style="min-width:300px;" ref="form" v-model="valid" lazy-validation>

            <v-avatar :color="colorInitials" size="56">
                <span class="white--text text-h5">{{ initials }}</span>

            </v-avatar>
            <v-row class="text-center titlenewrow" style="">

                <span>{{ namesurname }}</span>
            </v-row>


            <v-text-field v-model="profile.name" label="Nome" required :rules="genRules"></v-text-field>

            <v-text-field v-model="profile.surname" label="Cognome" required :rules="genRules"></v-text-field>

            <v-text-field v-model="profile.mail" :rules="emailRules" label="E-mail" required></v-text-field>



            <div class="padding" style="min-height:100px;"></div>
        </v-form>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                </v-text-field>
                            </v-toolbar>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="(tile, key) in filteredAziende" :key="key"
                                    @click="manageClickAziende(tile)">
                                    <v-icon aria-hidden="false">mdi-domain</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ tile }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetDipendenti">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Dipendenti

                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchDipendenti"></v-text-field>
                            </v-toolbar>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>
                                <v-list-item class="text-left" v-for="(objDip, key) in filteredDipendenti" :key="key"
                                    @click="manageClickDipendenti(objDip, 0)">
                                    <v-icon aria-hidden="false">mdi-account</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ objDip.fld_name }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>


        <div class="contpopupotpdeleteprofilecompany" style="display:none">

            <template>
                <modal name="popupOtpDeleteProfileCompany" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Codice OTP inviatoLe via mail

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="password" v-model="valueOtpDeleteProfile" label="" id="fldValueOtpDelProf">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row v-if="showResend">

                        <v-col cols="12" md="12" style="display: flex;
                                justify-content: center;
                                padding-top: 0px!important;
                                margin-top: 0px!important;">

                            <div>OTP non ricevuto ? </div>
                            <div style="font-weight: bold;
                                margin-left: 10px;
                                cursor: pointer;" class="clReinviaOtpDelProf">Reinvia</div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-del-prof-cmp" contain
                                    src="@/assets/btn_cancel3_funads.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelOtpDelProfCmp" />

                                <v-img alt="" class="btn-confirm-otp-del-prof-cmp" contain
                                    src="@/assets/btn_confirm3_funads.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmOtpDelProfCmp" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


    </v-container>
</template>

<script>
import apiprofile from "../utils/profile/apiprofile";
import apiotp from "../utils/otp/apiotp";
import apiprofilecandidate from "../utils/profilecandidate/apiprofilecandidate";

import {
    bus
} from "../main";
import router from ".././router";
import $ from 'jquery';
import _ from 'lodash';

export default {

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
        //window.removeEventListener("scroll", this.onScroll, false);

        //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
    },

    mounted: function () {

        this.setUrlDomainGlobal();

        window.portale = "jobmule";
        this.colorInitials = "#d00914";
        if (window.urlSiteCareers != "https://funads.eu/") {
            window.portale = "careers";
            this.colorInitials = "#14259b";
        }



        window.sendOtp = 1;

        var pointerVue = this;


        this.$root.$children[0].normalDiv1 = true;
        this.$root.$children[0].normalDiv2 = true;

        this.$root.$children[0].invoiceDiv1 = false;
        this.$root.$children[0].invoiceDiv2 = false;
        this.$root.$children[0].invoiceDiv3 = false;
        this.$root.$children[0].invoiceDiv4 = false;

        this.$root.$children[0].curTitle = "Profilo";




        this.dipendenti = this.$root.$children[0].dipendenti;

        this.gruppi = this.$root.$children[0].gruppi;

        this.aziende = this.$root.$children[0].aziende;
        this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        this.setupButtons();
        setTimeout(() => {
            this.syncProfile();

        }, 100);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_aziende":
                    //this.admindialog = true ;
                    this.$root.$children[0].showProgress = true;

                    setTimeout(() => {
                        this.sheetAziende = true;
                        this.$root.$children[0].showProgress = false;

                    }, 300);
                    break;

                case "btn_add_tms":

                    this.validate();

                    break;


                case "btn_delete_profile":

                    window.sendOtp = 1;

                    this.getOtpDelete();

                    break;




                case "btn_exit":
                    //alert("btn_add_x");
                    router.push({
                        path: "/dash"
                    });
                    break;

                default:
                    break;
            }
        });


        $(document).ready(function () {




            $('body').on('click', '.clReinviaOtpDelProf', function (event) {
                event.preventDefault();

                pointerVue.showResend = false;

                pointerVue.$modal.hide('popupOtpDeleteProfileCompany');

                window.sendOtp = 1;



                setTimeout(() => {

                    pointerVue.getOtpDelete();

                }, 300);


            });



        });



        this.setupButtons();
    },
    data: () => ({

        colorInitials: "",

        showResend: false,

        valueOtpDeleteProfile: "",

        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        profile: { name: "", mail: "", surname: "" },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {

        setUrlDomainGlobal: function () {

            let result = "";

            if (window.siteForTest == "") {

                if (location.hostname == "localhost") {
                    result = location.protocol + "//emtool.local/";
                }

                if (location.hostname != "localhost") {

                    result = location.protocol + "//" + location.hostname + "/";

                }

            }
            else {

                result = window.siteForTest;

            }



            window.urlSiteCareers = result;

        },


        sendMailDelete: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            window.myVal = "";


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprofilecandidate.mailDeleteProfileCandidate(
                v_token,
                that.profile.mail,
                window.portale,
                window.urlSiteCareers
            ).then((res) => {

                that.showSpinner = false;
                that.$root.$children[0].showProgress = false;
                console.log("res from mailDeleteProfileCandidate", res);

                that.$swal({
                    icon: "success",
                    text: "Le è stata inviata una mail per confermare la cancellazione",
                    showConfirmButton: false,
                    timer: 2000
                });

                that.$root.$children[0].logout();

            }).catch(err => {
                that.showSpinner = false;
                // that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );







        },





        getOtpDelete: async function () {

            var that = this;


            window.myVal = "";

            if (window.sendOtp == 1) {

                window.sendOtp = 0;

                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;

                var v_company_id = 0;

                if (window.portale == "careers") {

                    v_company_id = 7;

                    if (window.urlSiteCareers == "https://careers.ariall.eu/") {
                        v_company_id = 8;
                    }

                }



                var response = await apiotp.generateOTPAndSendMailLogin(
                    that.profile.mail,
                    window.portale,
                    'delete_profile_company',
                    v_company_id
                ).then((res) => {

                    that.showSpinner = false;
                    that.$root.$children[0].showProgress = false;
                    console.log("res from generateOTPAndSendMailLogin", res);

                    window.myVal = res.data.Result;


                    setTimeout(() => {

                        that.showResend = true;

                        that.valueOtpDeleteProfile = "";

                        $(".contpopupotpdeleteprofilecompany").show();

                        this.$modal.show('popupOtpDeleteProfileCompany');

                        setTimeout(() => {

                            $("#fldValueOtpDelProf").focus();

                        }, 300);


                    }, 200);





                }).catch(err => {
                    that.showSpinner = false;
                    // that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );


            }










        },




        btnCancelOtpDelProfCmp: function () {

            this.$modal.hide('popupOtpDeleteProfileCompany');



        },

        btnConfirmOtpDelProfCmp: function () {



            if (this.valueOtpDeleteProfile != "") {

                console.log("OTP INP: ", this.valueOtpDeleteProfile);
                console.log("ENC OTP INP: ", btoa(this.valueOtpDeleteProfile));

                if (window.myVal == btoa(this.valueOtpDeleteProfile)) {

                    this.$modal.hide('popupOtpDeleteProfileCompany');



                    this.sendMailDelete();


                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }
            else {

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }




        },


        changeDip: function () {

            /*   var token = window.$cookies.get("token");
              var system = window.$cookies.get("system");
              var user_id = window.$cookies.get("user_id");

              if (this.currDipendenteObj.resourceid !== undefined) {
                  //alert (  this.currDipendenteObj.resourceid  );
                  system = this.currDipendenteObj.v_res_id;
                  token = this.currDipendenteObj.v_user;
                  user_id = this.currDipendenteObj.v_user_id;

              } */

            var selNameDip = "";
            for (const [key, dip] of Object.entries(this.dipendenti)) {
                console.log("key", key);
                if (parseInt(dip.resourceid) == this.currDipendente) {
                    this.currDipendenteObj = dip;
                    console.log("dip", dip);
                    selNameDip = dip.fld_name;

                }
            }

            this.$root.$children[0].curDipTop = selNameDip;

            // console.log(token, system, user_id);
            this.syncProfile();

        },
        manageClickDipendenti: function (dipendente) {
            this.sheetDipendenti = false;
            this.currDipendente = dipendente.resourceid;
            //  alert ( dipendente );
            // this.resetDay();
            this.changeDip();
            setTimeout(() => {
                // this.syncMonth();

            }, 100);
            /*   this.syncArrayDipendenti();
              console.log ("CurAzienda",this.currAzienda );
              console.log(this.dipendenti);

              this.sheetDipendenti = true ; */

        },

        syncArrayDipendenti: async function () {

            console.log(this.currDipendente);

            this.currDipendente = "";
            this.$root.$children[0].curDipTop = "";

            // this.$root.$children[0].curDipOreTop = "";
            // this.$root.$children[0].curDipOreTopLabel = "";

            //aziende
            var arrAziende = [];
            var arrDipendenti = [];

            var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
            //   var aziende =   this.aziende  ;

            console.log("Elenco aziende:", this.aziende);
            // this.currAzienda
            //console.log ( "aziendeOK" , aziende );
            for (var az of aziende) {

                var currArAzienda = az[1].fld_name;
                console.log("az", az);
                arrAziende.push(az[1].fld_name);

                var dipArray = az[1].dipendenti;
                for (var dip of dipArray) {
                    //console.log ( dip.fld_name );
                    //  console.log ( dip );
                    if (currArAzienda == this.currAzienda) {
                        arrDipendenti.push(dip);

                    }
                }

            }
            //this.aziende = arrAziende;
            this.dipendenti = arrDipendenti;

        },

        manageClickAziende: async function (azienda) {

            this.currAzienda = azienda;
            await this.syncArrayDipendenti();

            console.log("CurAzienda", this.currAzienda);
            console.log(this.dipendenti);

            setTimeout(() => {
                this.sheetAziende = false;
                this.sheetDipendenti = true;
            }, 300);

        },

        manageClose: function () {

            this.setupButtons();
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.sheetDipendenti = false;
            this.sheetGruppi = false;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [];

                if (window.portale == "jobmule") {

                    pulsantis = [{
                        text: "Save",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_add_tms",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/save_update_data-funads.png",
                        title: "Salva",
                        width: 30

                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete-funads.png",
                        link: "/info",
                        id: "btn_delete_profile",
                        disabled: false,
                        title: "Elimina",
                        width: 30
                    }



                    ];


                }
                else {

                    pulsantis = [{
                        text: "Save",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_add_tms",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/save_update_data-careers2.png",
                        title: "Salva",
                        width: 30

                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete-careers2.png",
                        link: "/info",
                        id: "btn_delete_profile",
                        disabled: false,
                        title: "Elimina",
                        width: 30
                    }



                    ];

                }




                this.$root.$children[0].bnavbuttons = pulsantis;

                //   this.$root.$children[0].$refs.btn_add_tms;

                // console.log( "ok" ,  this.$root.$refs.mainApp );
                //this.$root.$refs.mainApp.bnavbuttons = pulsantis;

                //   refs.btn_add_tms
                // console.log ("Refs" , this.$root.$children[0].$refs.btn_add_tms );

                // var refs = this.$root.$children[0].$refs.btn_add_tms ;

                //   var add_tms = refs.btn_add_tms ;

                //d console.log(this.$root.$children[0].$refs.btn_add_tms);
                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        /*       setupButtons: async function () {
        setTimeout(() => {
            var pulsantis = [{
                text: "Dashboard",
                icon: "mdi-home-circle",
                link: "/dash",
                id: "btn_exit",
                disabled: false,
            }, ];
            var a = window.$cookies.get("a");
            if (a == 'Y' || a == 'S') {
                pulsantis.push({
                    text: "",
                    icon: "mdi-message-plus",
                    link: "/dash",
                    id: "btn_aziende",
                    disabled: false,
                }, );
            }
            this.$root.$children[0].bnavbuttons = pulsantis;
        }, 100);
    },
*/

        saveProfile: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprofilecandidate.setProfileCandidate(
                that.profile,
                v_token,
                window.portale
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setProfileCandidate", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }


            );

        },



        saveProfileOld: async function () {

            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");


            if (this.currDipendenteObj.resourceid !== undefined) {
                username = atob(this.currDipendenteObj.v_user);
                token_system = this.currDipendenteObj.way;

            }

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {

                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {

                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                /*     this.profile = this.iban = response.data.Result;
                       //console.log("HERE",response.data.Result.iban);
                    this.iban = response.data.Result.iban ;
                    this.email = response.data.Result.mail ;
                    this.codicefiscale = response.data.Result.codice_fiscale ;
                    this.cellulare = response.data.Result.tel_cell ;
                    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
                    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;

            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },


        syncProfile: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiprofilecandidate.getProfileCandidate(
                v_token,
                window.portale
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getProfileCandidate", res);

                that.profile.name = res.data.Result.nome_utente;
                that.profile.surname = res.data.Result.cognome_utente;
                that.profile.mail = res.data.Result.email;

                that.initials = res.data.Result.nome_utente.charAt(0) + "" + res.data.Result.cognome_utente.charAt(0);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },











        syncProfileOld: async function () {
            /*  var token = window.$cookies.get("token");
             var system = window.$cookies.get("system"); */

            setTimeout(async () => {

                var username = window.$cookies.get("username");
                var token_system = window.$cookies.get("token_system");

                console.log("oki", this.currDipendenteObj);

                if (this.currDipendenteObj.resourceid !== undefined) {
                    //alert (  this.currDipendenteObj.resourceid  );
                    token_system = this.currDipendenteObj.way;
                    username = atob(this.currDipendenteObj.v_user);

                }

                //   console.log ( system );
                this.showSpinner = true;
                this.$root.$children[0].showProgress = true;

                var result;
                try {
                    // alert ( username + " +" + token_system );
                    result = await apiprofile.getProfile(
                        username,
                        token_system
                    ).then((response) => {
                        // alert ("finshed");

                        // console.log (response);
                        this.showSpinner = false;
                        this.$root.$children[0].showProgress = false;

                        try {

                            this.profile = response.data.Result;
                            this.profile.username = username;
                            //console.log("HERE",response.data.Result.iban);
                            this.iban = response.data.Result.iban;
                            this.email = response.data.Result.mail;
                            this.codicefiscale = response.data.Result.codice_fiscale;
                            this.cellulare = response.data.Result.tel_cell;
                            this.namesurname = response.data.Result.name + " " + response.data.Result.surname;
                            this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0);

                            //this.monthArray = response.data.Timesheet;

                        } catch (error) {
                            console.log(error);
                            alert(error.message);
                        }

                        console.log(result);

                    });
                } catch (error) {
                    alert(error.message);

                    this.showSpinner = false;
                    this.$root.$children[0].showProgress = false;
                }

            }, 300);

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
  console.log('a phone number was clicked');
}    <template>
    <div class="text-center">
        <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
            <v-sheet class="text-center" height="100vh">
                <div class="headerSheetAziende" fixed style="position:fixed;">
                    Aziende
                    <v-btn class='closeSheet' icon @click="manageClose()">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                    <v-toolbar>
                        <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"></v-text-field>
                    </v-toolbar>
                </div>
                <!--  <v-btn class="mt-2" text color="yellow" @click="sheetAziende = !sheetAziende">
                    close
                </v-btn> -->
                <v-divider></v-divider>
                <div class="spacer" style="min-height:72px;"></div>
                <v-flex xs12 sm12>
                    <v-card>

                        <v-list-item class="text-left" v-for="(tile,key) in filteredAziende" :key="key" @click="manageClickAziende(tile)">
                            <v-icon aria-hidden="false">mdi-domain</v-icon>
                            <v-list-item-title class='itemAzienda'>{{ tile }}</v-list-item-title>
                            <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                        </v-list-item>
                    </v-card>
                </v-flex>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

,
*/
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {

                this.$swal({
                    icon: "error",
                    text: "Verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });

            } else {

                console.log("Profile: ", this.profile);

                this.saveProfile();

            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;

}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}

.editProfile .v-input {
    font-size: 1.2em !important;
}

.editProfile .v-select {
    font-size: 1.2em !important;
}

.editProfile .v-label {
    font-size: 1em !important;
}
</style>
