import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APICareers {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }



  async saveSettings(user, settings, imglogo, imgheader, imgparallax, removeimglogo, removeimgheader, removeimgparallax, url_site) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("imglogo", imglogo);
    formData.append("imgheader", imgheader);
    formData.append("imgparallax", imgparallax);
    formData.append("removeimglogo", removeimglogo);
    formData.append("removeimgheader", removeimgheader);
    formData.append("removeimgparallax", removeimgparallax);
    formData.append("url_site", url_site);

    for (var key in settings) {
      formData.append(key, settings[key]);
    }

    return axios.post(this.getUrlDomain("FunAdsSaveSettings"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getSettings(url_site) {

    let formData = new FormData();

    formData.append("url_site", url_site);
   
   
    return axios.post(this.getUrlDomain("FunAdsGetSettings"), formData, { 'Content-Type': 'multipart/form-data' });

  }






  async saveLogo(user, imglogo, removeimglogo, url_site) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("imglogo", imglogo);
    formData.append("removeimglogo", removeimglogo);
    formData.append("url_site", url_site);

    return axios.post(this.getUrlDomain("FunAdsSaveLogo"), formData, { 'Content-Type': 'multipart/form-data' });

  }







  async saveImagesBackground(user, imgheader, imgparallax, imgheaderviewdetails, imgheadergetstart, removeimgheader, removeimgparallax, removeheaderviewdetails, removeheadergetstart, url_site) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("imgheader", imgheader);
    formData.append("imgparallax", imgparallax);  
    formData.append("imgheaderviewdetails", imgheaderviewdetails);
    formData.append("imgheadergetstart", imgheadergetstart);
    formData.append("removeimgheader", removeimgheader);
    formData.append("removeimgparallax", removeimgparallax);
    formData.append("removeheaderviewdetails", removeheaderviewdetails);
    formData.append("removeheadergetstart", removeheadergetstart);
    formData.append("url_site", url_site);

    return axios.post(this.getUrlDomain("FunAdsSaveImagesBackground"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveCompany(user, settings, url_site) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("url_site", url_site);

    for (var key in settings) {
      formData.append(key, settings[key]);
    }

    return axios.post(this.getUrlDomain("FunAdsSaveCompany"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveRSS(user, settings, url_site) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("url_site", url_site);

    for (var key in settings) {
      formData.append(key, settings[key]);
    }

    return axios.post(this.getUrlDomain("FunAdsSaveRSS"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async savePoweredBy(user, settings) {

    let formData = new FormData();

    formData.append("username", user);

    for (var key in settings) {
      formData.append(key, settings[key]);
    }

    return axios.post(this.getUrlDomain("CareersSavePoweredBy"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async savePrivacy(user, settings, url_site) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("url_site", url_site);

    for (var key in settings) {
      formData.append(key, settings[key]);
    }

    return axios.post(this.getUrlDomain("FunAdsSavePrivacy"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async savePrivacyHome(user, settings, url_site) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("url_site", url_site);

    for (var key in settings) {
      formData.append(key, settings[key]);
    }

    return axios.post(this.getUrlDomain("FunAdsSavePrivacyHome"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async saveTermOfUse(user, settings, url_site) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("url_site", url_site);

    for (var key in settings) {
      formData.append(key, settings[key]);
    }

    return axios.post(this.getUrlDomain("FunAdsSaveTermOfUse"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async sendCV(firstname, surname, email, telefono, idads, file, companyid, valdegree, valprov, valskill, url_site) {

    let formData = new FormData();

    formData.append("firstname", firstname);
    formData.append("surname", surname);
    formData.append("email", email);
    formData.append("telefono", telefono);
    formData.append("idads", idads);
    formData.append("file", file);
    formData.append("companyid", companyid);
    formData.append("valdegree", valdegree);
    formData.append("valprov", valprov);
    formData.append("valskill", valskill);
    formData.append("url_site", url_site);

    return axios.post(this.getUrlDomain("CareersSendCV"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async sendCVJobMule(firstname, surname, email, telefono, idads, file, companyid, valdegree, valprov, valskill, url_site) {

    let formData = new FormData();

    formData.append("firstname", firstname);
    formData.append("surname", surname);
    formData.append("email", email);
    formData.append("telefono", telefono);
    formData.append("idads", idads);
    formData.append("file", file);
    formData.append("companyid", companyid);
    formData.append("valdegree", valdegree);
    formData.append("valprov", valprov);
    formData.append("valskill", valskill);
    formData.append("url_site", url_site);

    return axios.post(this.getUrlDomain("CareersSendCVJobMule"), formData, { 'Content-Type': 'multipart/form-data' });

  }





  async searchJobs(url_site, filter_ads_featured, num_latest_jobs, filter_ads_id_sub_cat, filter_ads_id_city, filter_ads_id_cat, filter_ads_txt_search, filter_ads_id_regione, filter_ads_id_comune, filter_ads_job_by_title, filter_ads_job_by_state, filter_ads_job_by_city, filter_ads_job_by_exp, filter_ads_job_by_type, filter_ads_job_by_career, filter_ads_job_by_degree, filter_ads_job_by_industry, filter_ads_job_by_func_area, filter_ads_job_by_skill) {

    let formData = new FormData();

    formData.append("url_site", url_site);
    formData.append("filter_ads_featured", filter_ads_featured);
    formData.append("num_latest_jobs", num_latest_jobs);
    formData.append("filter_ads_id_sub_cat", filter_ads_id_sub_cat);
    formData.append("filter_ads_id_city", filter_ads_id_city);
    formData.append("filter_ads_id_cat", filter_ads_id_cat);
    formData.append("filter_ads_txt_search", filter_ads_txt_search);
    formData.append("filter_ads_id_regione", filter_ads_id_regione);
    formData.append("filter_ads_id_comune", filter_ads_id_comune);
    formData.append("filter_ads_job_by_title", filter_ads_job_by_title);
    formData.append("filter_ads_job_by_state", filter_ads_job_by_state);
    formData.append("filter_ads_job_by_city", filter_ads_job_by_city);
    formData.append("filter_ads_job_by_exp", filter_ads_job_by_exp);
    formData.append("filter_ads_job_by_type", filter_ads_job_by_type);
    formData.append("filter_ads_job_by_career", filter_ads_job_by_career);
    formData.append("filter_ads_job_by_degree", filter_ads_job_by_degree);
    formData.append("filter_ads_job_by_industry", filter_ads_job_by_industry);
    formData.append("filter_ads_job_by_func_area", filter_ads_job_by_func_area);
    formData.append("filter_ads_job_by_skill", filter_ads_job_by_skill);


    return axios.post(this.getUrlDomain("CareersSearchJobs"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async saveTitleHeader(user, settings, url_site) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("url_site", url_site);

    for (var key in settings) {
      formData.append(key, settings[key]);
    }

    return axios.post(this.getUrlDomain("FunAdsSaveTitleHeader"), formData, { 'Content-Type': 'multipart/form-data' });

  }





}

export default new APICareers({
  url: "https://services.ebadge.it/public/api/"
})
