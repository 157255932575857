<template>
  <div class="v-overflow v-overflow-bt-padding ads-getstarted">



    <!-- start section header -->

    <div class="header"
      style="    position: fixed;
                                                                                                                                                                                                                                                                                                                                                min-width: 100vw;
                                                                                                                                                                                                                                                                                                                                                min-height: 54px;
                       
                                                                                                                                                                                                                                                                                                                                                background-color: white;
                                                                                                                                                                                                                                                                                                                                                z-index: 100;">
      <div class="container-fluid">

        <div class="row">

          <div class="col-lg-2 col-md-6 col-6" style="display:flex;align-items:center;">


            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon id="no-background-hover" to="/initSearchAds" style="margin-left: 27px;">

                  <v-img :src="pathLogo" style="width:100px" />

                </v-btn>
              </template>

            </v-tooltip>


          </div>

          <div class="col-lg-10 col-md-6 col-6">

            <ul style="display:flex;justify-content:end;" class="navbar-nav">


              <li class="nav-item">
                <a id="btnAllJobs" class="nav-link">
                  <img src="@/assets/all_jobs.jpg" class="clButtonHeader" title="Jobs" />
                </a>
              </li>

              <li class="nav-item">
                <div class="clPipe"></div>
              </li>

              <li class="nav-item">
                <a id="btnSignInCompany" class="nav-link">
                  <img src="@/assets/login_aziende.jpg" class="clButtonHeader" title="Login Aziende" />
                </a>
              </li>

              <li class="nav-item">
                <a id="btnSignInCandidate" class="nav-link">
                  <img src="@/assets/login_candidati.jpg" class="clButtonHeader" title="Login Candidati" />
                </a>
              </li>

            </ul>

          </div>

        </div>

      </div>


    </div>

    <!-- end section header -->

    <!-- start section title -->

    <div class="pageTitle" style="margin-top:75px">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <h1 class="page-heading" style="font-family: 'Orbitron', sans-serif!important;">Register</h1>
          </div>
          <div class="col-md-6 col-sm-6">
            <!-- <div class="breadCrumb"><a id="gotToHome"
                style="font-family: 'Orbitron', sans-serif!important;font-size:14px">Home</a> /
              <span style="font-family: 'Orbitron', sans-serif!important;font-size:14px">Register</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- end section title -->



    <!-- start section details -->

    <div class="listpgWraper">


      <div class="container">


        <div class="row" style="justify-content: center;">


          <!-- <div class="col-lg-3">&nbsp;</div> -->


          <div class="col-lg-5">

            <div class="jobButtons applybox">


              <div id="candidate" class="formpanel tab-pane ">


                <form class="form-horizontal">


                  <div class="formrow">

                    <input type="text" name="name" class="form-control" placeholder="First Name" value=""
                      style="font-family: 'Orbitron', sans-serif!important;" id="firstName">

                  </div>

                  <div class="formrow">

                    <input type="text" name="surname" class="form-control" placeholder="Surname" value=""
                      style="font-family: 'Orbitron', sans-serif!important;" id="surname">

                  </div>

                  <div class="formrow">

                    <input type="email" name="email" class="form-control" placeholder="Email" value=""
                      style="font-family: 'Orbitron', sans-serif!important;" id="email">

                  </div>

                  <div class="formrow">

                    <input type="telefono" name="telefono" class="form-control" placeholder="Telefono" value=""
                      style="font-family: 'Orbitron', sans-serif!important;" id="telefono">

                  </div>


                  <div class="formrow">

                    <ion-item class="form-control" style="margin-left:13px;padding:0!important">

                      <ion-select id="iddegreelevel" name="iddegreelevel" class='iddegreelevel' cancel-Text="Chiudi"
                        placeholder="Select Degree Level" done-Text="" :value="valDegreeLevel" interface="action-sheet"
                        style="font-family: 'Orbitron', sans-serif!important;font-size:14px!important">

                        <ion-select-option v-for="item in degreeLevel" :key="item.id" :value='item.id'
                          style="font-family: 'Orbitron', sans-serif!important;">
                          {{
                            item.description }}</ion-select-option>

                      </ion-select>
                    </ion-item>

                  </div>



                  <div class="formrow">

                    <ion-item class="form-control" style="margin-left:13px;padding:0!important">

                      <ion-select id="idregione" name="idregione" class='idregione' cancel-Text="Chiudi"
                        placeholder="Select Region" done-Text="" :value="valReg" interface="action-sheet"
                        style="font-family: 'Orbitron', sans-serif!important;font-size:14px!important">

                        <ion-select-option v-for="item in regioni" :key="item.idregione" :value='item.idregione'
                          style="font-family: 'Orbitron', sans-serif!important;">
                          {{
                            item.nome }}</ion-select-option>

                      </ion-select>
                    </ion-item>

                  </div>




                  <div class="formrow">

                    <ion-item class="form-control" style="margin-left:13px;padding:0!important">

                      <ion-select id="idprov" name="idprov" class='idprov' cancel-Text="Chiudi"
                        placeholder="Select Province" done-Text="" :value="valProv" interface="action-sheet"
                        style="font-family: 'Orbitron', sans-serif!important;font-size:14px!important">

                        <ion-select-option v-for="item in provs" :key="item.id" :value='item.id'
                          style="font-family: 'Orbitron', sans-serif!important;">
                          {{
                            item.description }}</ion-select-option>

                      </ion-select>
                    </ion-item>

                  </div>


                  <div class="formrow">

                    <input type="skill" name="skill" id="skill" class="form-control" placeholder="Skills" value=""
                      style="font-family: 'Orbitron', sans-serif!important">

                  </div>




                  <div class="formrow">

                    <input type="file" name="cv_file" class="form-control" placeholder="Cv" value=""
                      style="font-family: 'Orbitron', sans-serif!important;" id="attachment">

                  </div>



                  <div class="formrow">


                    <div class="contCond formrow">


                      <input type="checkbox" checked="" value="1" name="terms_of_use" class="termOfUse">

                      <a id="openTermUse"
                        style="margin-left:4px;padding: 3px 12px;font-family: 'Orbitron', sans-serif!important;">I
                        accept Privacy</a>




                    </div>



                    <input type="button" style="display:block;font-family: 'Orbitron', sans-serif!important;" class="btn"
                      value="Apply" id="btnApply">



                  </div>










                </form>





              </div>



            </div>


          </div>


          <!-- <div class="col-lg-4">&nbsp;</div> -->




        </div>



      </div>






    </div>


    <!-- end section details -->






    <!-- start section footer -->

    <div class="footerWrap">
      <div class="container">
        <div class="row">

          <!--Quick Links-->
          <div class="col-md-3 col-sm-6">
            <h5 style="font-family: 'Orbitron', sans-serif!important;">Quick Links</h5>
            <!--Quick Links menu Start-->
            <ul class="quicklinks">

              <li class="">

                <a style="font-family: 'Orbitron', sans-serif!important;" class="lnkTermOfUse">Terms Of Use</a>
                <a style="font-family: 'Orbitron', sans-serif!important;" class="lnkPrivacyHome">Privacy</a>

              </li>
            </ul>
          </div>
          <!--Quick Links menu end-->

          <div class="col-md-3 col-sm-6">
            <h5 style="font-family: 'Orbitron', sans-serif!important;">Jobs By Functional Area</h5>
            <!--Quick Links menu Start-->

            <ul class="quicklinks" id="jobsByFuncAreaQuickLinks">




            </ul>

          </div>

          <!--Jobs By Industry-->
          <div class="col-md-3 col-sm-6">
            <h5 style="font-family: 'Orbitron', sans-serif!important;">Jobs By Industry</h5>
            <!--Industry menu Start-->

            <ul class="quicklinks" id="jobsByIndustryQuickLinks">

            </ul>



            <!--Industry menu End-->
            <div class="clear"></div>
          </div>

          <!--About Us-->
          <div class="col-md-3 col-sm-12">
            <h5 style="font-family: 'Orbitron', sans-serif!important;">Contact Us</h5>

            <div class="txtInfoCompany" style="font-family: 'Orbitron', sans-serif!important;color:white">


            </div>



            <div class="social"></div>
            <!-- Social Icons end -->

          </div>
          <!--About us End-->


        </div>
      </div>
    </div>

    <!-- end section footer -->

    <!-- start section copyright -->


    <div class="copyright" style="padding:0!important">
      <div class="container">
        <div class="row">
          <div class="col-md-8">


            <div class="bttxt">

            </div>



          </div>
          <div class="col-md-4">

          </div>
        </div>

      </div>
    </div>

    <!-- end section copyright -->


    <div class="contpopupotpdelete" style="display:none">

      <template>
        <modal name="popupOtpDelete" :clickToClose="false" :width="262" :height="215">


          <v-row>

            <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

              Inserire codice OTP ricevuto via mail

            </v-col>

          </v-row>

          <v-row>

            <v-col cols="12" md="12">

              <v-text-field type="password" v-model="valueOtpDelete" label="" id="fldValueOtpDelete">
              </v-text-field>

            </v-col>

          </v-row>

          <v-row v-if="showResend">

            <v-col cols="12" md="12" style="    display: flex;
              justify-content: center;
              padding-top: 0px!important;
              margin-top: 0px!important;">

              <div>OTP non ricevuto ? </div>
              <div style="font-weight: bold;
              margin-left: 10px;
              cursor: pointer;" class="clReinviaOtp">Reinvia</div>

            </v-col>

          </v-row>

          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">

                <v-img alt="" class="btn-cancel-otp-delete" contain src="@/assets/btn_cancel3_careers.png" max-width="30"
                  max-height="30" style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                  title="Annulla" @click="btnCancelOtpDelete" />

                <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_careers.png"
                  max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" title="Conferma"
                  @click="btnConfirmOtpDelete" />


              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>

    <div class="contpopupprivacy" style="display:none">

      <template>
        <modal name="popupPrivacy" :clickToClose="false" :width="getWindowMobileTermOfUse()" :height="460">


          <v-row>

            <v-col cols="12" md="12">


              <div class="clPrivacy">



              </div>



            </v-col>

          </v-row>



          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">


                <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_funads.png"
                  max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" title="Conferma"
                  @click="btnConfirmPrivacy" />


              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>


    <div class="contpopuptermuse" style="display:none">

      <template>
        <modal name="popupTermUse" :clickToClose="false" :width="getWindowMobileTermOfUse()" :height="460">


          <v-row>

            <v-col cols="12" md="12">


              <div class="clTermOfUse">



              </div>



            </v-col>

          </v-row>



          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">


                <v-img alt="" class="btn-confirm-otp-delete" contain src="@/assets/btn_confirm3_funads.png"
                  max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" title="Conferma"
                  @click="btnConfirmTermOfUse" />


              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>




    <div class="contpopupprivacyhome" style="display:none">

      <template>
        <modal name="popupPrivacyHome" :clickToClose="false" :width="getWindowMobilePrivacyHome()" :height="460">


          <v-row>

            <v-col cols="12" md="12">


              <div class="clPrivacyHome">



              </div>



            </v-col>

          </v-row>



          <v-row>

            <v-col cols="12" md="12">

              <div style="display:flex;justify-content:center">


                <v-img alt="" class="btn-confirm-privacy-home" contain src="@/assets/btn_confirm3_careers.png"
                  max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" title="Conferma"
                  @click="btnConfirmPrivacyHome" />


              </div>

            </v-col>

          </v-row>

        </modal>
      </template>

    </div>








  </div>
</template>
  
  
<style scoped lang="css">
@import "./../assets/annunci/bootstrap-datepicker3.min.css";
@import "./../assets/annunci/bootstrap.min.css";
@import "./../assets/annunci/main.css";
/* @import "./../assets/annunci/owl.carousel.css"; */
@import "./../assets/annunci/select2-bootstrap.min.css";
@import "./../assets/annunci/select2.min.css";
/* @import "./../assets/annunci/settings.css"; */
/* @import "./../assets/annunci/slick-theme.css"; */
/* @import "./../assets/annunci/slick-theme.min.css"; */
@import "./../assets/annunci/slick.css";
@import "./../assets/annunci/style-font1.css";
@import "./../assets/annunci/style-font2.css";
@import "./../assets/annunci/font-awesome.css";
</style>
  
<script>
//@import "@/assets/loginstart/style.css"
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

//import RiseLoader from "vue-spinner/src/RiseLoader.vue";

import router from ".././router";
import apisearchads from "../utils/searchads/apisearchads";
import apiotp from "../utils/otp/apiotp";
import apicareers from "../utils/careers/apicareers";

import $ from 'jquery';

/* import Vue from 'vue' */
//import App from '.././App.vue'

export default {
  // components: {
  //   /* PulseLoader, */
  //   RiseLoader,
  // },

  updated() { },


  mounted: function () {

    this.setUrlDomainGlobal();


    $(".pageTitle").css("background", "url('')");

    $('.termOfUse').prop('checked', false);

    $('#btnApply').hide();


    window.sendOtp = 1;

    window.myVal = "";

    var loggedIn = this.$cookies.get('token');

    if (loggedIn) {
      router.push({ path: "/dash" });
    }
    console.log("Ciao: " + this.userLogged);

    var pointerVue = this;

    setTimeout(() => {

      pointerVue.getValuesSearch();


    }, 100);


    // $("#divMenu").hide();


    console.log("RELOAD ONCE: ", window.reloadOnce);



    $(document).ready(function () {


      $('body').on('click', '.clReinviaOtp', function (event) {
        event.preventDefault();

        pointerVue.showResend = false;

        pointerVue.$modal.hide('popupOtpDelete');

        window.sendOtp = 1;


        setTimeout(() => {

          pointerVue.getOtpSendCV();

        }, 300);


      });

      $('body').on('ionChange', '.idregione', function (event) {
        event.preventDefault();


        pointerVue.setProvince(this.value);

      });



      $('body').on('ionChange', '.iddegreelevel', function (event) {
        event.preventDefault();

        pointerVue.valDegreeLevel = this.value;

      });


      $('body').on('ionChange', '.idprov', function (event) {
        event.preventDefault();

        pointerVue.valProv = this.value;

      });


      $('body').on('click', '.lnkTermOfUse', function (event) {
        event.preventDefault();


        $(".contpopuptermuse").show();

        pointerVue.$modal.show('popupTermUse');


        setTimeout(() => {

          $(".clTermOfUse").html(pointerVue.txtTermOfUse);

        }, 200);


      });




      $('body').on('click', '.lnkPrivacyHome', function (event) {
        event.preventDefault();


        router.push({ path: '/privacyHome' }).catch(() => { });



      });






      $('body').on('click', '.clButtonRefresh', function (event) {
        event.preventDefault();

        router.push({ path: '/reloadPage/getStartToday' }).catch(() => { });


      });


      $('body').on('click', '#btnHome', function (event) {
        event.preventDefault();

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", "", "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");

        window.$cookies.set("filter_ads_txt_search", "", "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");

        router.push({ path: '/searchAds' }).catch(() => { });

      });



      $('body').on('click', '#btnAllJobs', function (event) {
        event.preventDefault();

        window.$cookies.set("filter_ads_id_cat", "", "9y");

        window.$cookies.set("filter_ads_id_city", "", "9y");

        window.$cookies.set("filter_ads_id_sub_cat", "", "9y");

        window.$cookies.set("filter_ads_featured", "", "9y");

        window.$cookies.set("num_latest_jobs", "", "9y");

        window.$cookies.set("filter_ads_txt_search", "", "9y");

        window.$cookies.set("filter_ads_id_regione", "", "9y");

        window.$cookies.set("filter_ads_id_comune", "", "9y");

        router.push({ path: '/adsGrid' }).catch(() => { });

      });



      $('body').on('click', '.termOfUse', function (event) {

        console.log(event);

        console.log("TERM_OF_USE");


        if ($(this).prop("checked")) {


          $('#btnApply').show();

        }
        else {

          $('#btnApply').hide();
        }



      });





      $('body').on('click', '#openTermUse', function (event) {
        event.preventDefault();

        console.log("OPEN_TERM_OF_USE");

        $(".contpopupprivacy").show();

        pointerVue.$modal.show('popupPrivacy');


        setTimeout(() => {

          $(".clPrivacy").html(pointerVue.txtPrivacy);

        }, 200);


      });




      $('body').on('click', '#btnApply', function (event) {
        event.preventDefault();

        console.log("BTN_APPLY");

        pointerVue.validate();

      });





      $('body').on('click', '#gotToHome', function (event) {
        event.preventDefault();

        router.push({ path: '/searchAds' }).catch(() => { });


      });


      $('body').on('ionChange', '.idregione', function (event) {
        event.preventDefault();

        pointerVue.setComuni(this.value);


      });

      $('body').on('click', '#btnSignInCompany', function (event) {
        event.preventDefault();

        window.$cookies.set("page_go_back", "getStartToday", "9y");

        router.push({ path: '/' }).catch(() => { });

      });

      $('body').on('click', '#btnSignInCandidate', function (event) {
        event.preventDefault();

        window.$cookies.set("page_go_back", "getStartToday", "9y");

        router.push({ path: '/loginCandidati' }).catch(() => { });

      });







      $('body').on('click', '.goToHome', function (event) {
        event.preventDefault();

        router.push({ path: '/searchAds' }).catch(() => { });

      });




      $('body').on('click', '.viewAds', function (event) {
        event.preventDefault();

        var v_id = $(this).attr("data-id");

        console.log("VAL ID: ", v_id);

      });



    });



  },


  // data:

  name: "Login",
  data() {
    return {

      txtPrivacyHome: "",


      showResend: true,

      skills: [],
      idPermSel: [],

      valDegreeLevel: "",
      valProv: "",

      degreeLevel: [],
      provs: [],
      provsTemp: [],

      txtTermOfUse: "",

      txtPrivacy: "",

      widthTermOfUse: 0,

      valueOtpDelete: "",

      nameAziOverview: "",
      logoAziOverview: "",
      addressCompanyOverview: "",
      txtNumJobsOpen: "",
      urlSiteCompany: "",


      skillDetails: null,


      titleAds: "",
      datePosted: "",
      descLocation: "",
      tipoContratto: "",
      careerLevel: "",
      yearExperience: "",
      titoloStudio: "",
      adsNumber: "",
      textPoweredBy: "",
      applyBefore: "",
      descriptionJob: "",


      pathLogo: "",

      dialogRegister: true,

      notifications: false,
      sound: true,
      widgets: false,

      showSpinner: false,
      input: {
        username: "",
        password: "",
      },

      nameSearch: "",
      funcAreaSearch: "0",
      regioneSearch: "0",
      comuneSearch: "0",

      funcArea: [],
      regioni: [],
      comuni: [],
      comuniTemp: [],


      items: [],

      //items: [{ id: "tbAnalysysTools", name: "Analysys Tools" }],


      adsFuncArea: [],
      adsCities: [],
      adsIndustries: [],

      tab: null,


    };
  },
  methods: {


    btnConfirmPrivacyHome: function () {

      this.$modal.hide('popupPrivacyHome');

    },



    getWindowMobilePrivacyHome: function () {

      if (window.innerWidth <= 768) {

        return 350;

      }
      else {

        return 616;

      }

    },




    setUrlDomainGlobal: function () {

      let result = "";

      if (window.siteForTest == "") {

        if (location.hostname == "localhost") {
          result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

          result = location.protocol + "//" + location.hostname + "/";

        }

      }
      else {

        result = window.siteForTest;

      }



      window.urlSiteCareers = result;

    },



    setProvince: function (id_regione) {

      console.log("PROV SEL 2: ", id_regione);

      console.log("PROVINCE TMP: ", this.provsTemp);



      this.provs = [];


      var Provs = [];

      Provs.push({
        id: "",
        description: "Select Province"
      });

      for (var i = 0; i < this.provsTemp.length; i++) {

        if (parseInt(this.provsTemp[i].idregione) == parseInt(id_regione)) {

          Provs.push({
            id: this.provsTemp[i].nome,
            description: this.provsTemp[i].nome
          });

        }

      }

      console.log("PROVINCE SEL: ", Provs);

      this.provs = Provs;

      this.valProv = "";


    },



    btnConfirmTermOfUse: function () {

      this.$modal.hide('popupTermUse');


    },


    sendCV: async function () {

      var that = this;

      var id_ads = window.$cookies.get("id_ads_detail");

      console.log("FIRST NAME: ", $("#firstName").val());
      console.log("SURNAME: ", $("#surname").val());
      console.log("EMAIL: ", $("#email").val());
      console.log("TELEFONO: ", $("#telefono").val());
      console.log("ID_ADS: ", id_ads);
      console.log("ATTACHMENT: ", $('#attachment')[0].files[0]);

      console.log("DEGREE LEVEL: ", that.valDegreeLevel);
      console.log("PROV: ", that.valProv);


      var response = null;

      if (window.urlSiteCareers != "https://funads.eu/") {

        that.showSpinner = true;
        that.$root.$children[0].showProgress = true;

        response = await apicareers.sendCV(
          $("#firstName").val(),
          $("#surname").val(),
          $("#email").val(),
          $("#telefono").val(),
          0,
          $('#attachment')[0].files[0],
          7,
          that.valDegreeLevel,
          that.valProv,
          $("#skill").val(),
          window.urlSiteCareers
        ).then((res) => {

          that.$root.$children[0].showProgress = false;
          console.log("res from sendCV", res);

          that.$swal({
            icon: "success",
            text: "CV inviato correttamente una mail di conferma Le è stata inviata",
            showConfirmButton: false,
            timer: 3000
          });

          setTimeout(() => {

            router.push({
              path: "/searchAds"
            });

          }, 3000);




        }).catch(err => {
          that.$root.$children[0].showProgress = false;
          console.log(err);
          var msg = err.response.data.Error;

          that.$swal({
            icon: "error",
            text: msg,
            showConfirmButton: false,
            timer: 8000
          });
          console.log("Errori", "Non è stato possibile eliminare i dati");
          console.log("response", response);

        }

        );

      }
      else {


        that.showSpinner = true;
        that.$root.$children[0].showProgress = true;

        response = await apicareers.sendCVJobMule(
          $("#firstName").val(),
          $("#surname").val(),
          $("#email").val(),
          $("#telefono").val(),
          0,
          $('#attachment')[0].files[0],
          7,
          that.valDegreeLevel,
          that.valProv,
          $("#skill").val(),
          window.urlSiteCareers
        ).then((res) => {

          that.$root.$children[0].showProgress = false;
          console.log("res from sendCV", res);

          that.$swal({
            icon: "success",
            text: "CV inviato correttamente una mail di conferma Le è stata inviata",
            showConfirmButton: false,
            timer: 3000
          });

          setTimeout(() => {

            router.push({
              path: "/searchAds"
            });

          }, 3000);




        }).catch(err => {
          that.$root.$children[0].showProgress = false;
          console.log(err);
          var msg = err.response.data.Error;

          that.$swal({
            icon: "error",
            text: msg,
            showConfirmButton: false,
            timer: 8000
          });
          console.log("Errori", "Non è stato possibile eliminare i dati");
          console.log("response", response);

        }

        );


      }







    },

    getWindowMobileTermOfUse: function () {

      if (window.innerWidth <= 768) {

        return 350;

      }
      else {

        return 616;

      }

    },


    btnConfirmPrivacy: function () {


      this.$modal.hide('popupPrivacy');

    },

    btnCancelOtpDelete: function () {

      window.sendOtp = 1;

      this.$modal.hide('popupOtpDelete');

    },

    btnConfirmOtpDelete: function () {

      if (this.valueOtpDelete != "") {

        console.log("OTP INP: ", this.valueOtpDelete);
        console.log("ENC OTP INP: ", btoa(this.valueOtpDelete));

        if (window.myVal == btoa(this.valueOtpDelete)) {

          this.$modal.hide('popupOtpDelete');

          this.sendCV();


        }
        else {

          this.$swal({
            icon: "error",
            text: "Valore OTP non corretto",
            showConfirmButton: false,
            timer: 2000
          });


        }



      }
      else {

        this.$swal({
          icon: "error",
          text: "Specificare un  valore dell'OTP",
          showConfirmButton: false,
          timer: 2000
        });

      }


    },



    getOtpSendCV: async function () {

      var that = this;


      var ids = "";

      $(".val_status").each(function (index) {

        console.log(index);

        if ($(this).prop("checked")) {

          if (ids != "") {
            ids = ids + ",";
          }
          ids = ids + $(this).attr("data-id");
        }

      });

      console.log("IDS SEL:", ids);


      window.myVal = "";

      if (window.sendOtp == 1) {

        window.sendOtp = 0;


        that.showSpinner = true;
        that.$root.$children[0].showProgress = true;

        var response = await apiotp.generateOTPAndSendMailSendCV(
          $("#email").val(),
          'careers',
          'send_cv',
          7
        ).then((res) => {

          that.$root.$children[0].showProgress = false;
          console.log("res from generateOTPAndSendMailSendCV", res);

          window.myVal = res.data.Result;


          setTimeout(() => {

            that.showResend = true;


            $(".contpopupotpdelete").show();

            that.valueOtpDelete = "";

            that.$modal.show('popupOtpDelete');

            setTimeout(() => {

              $("#fldValueOtpDelete").focus();

            }, 300);


          }, 200);





        }).catch(err => {
          that.$root.$children[0].showProgress = false;
          console.log(err);
          var msg = err.response.data.Error;

          that.$swal({
            icon: "error",
            text: msg,
            showConfirmButton: false,
            timer: 8000
          });
          console.log("Errori", "Non è stato possibile eliminare i dati");
          console.log("response", response);

        }

        );



      }









    },


    validateEMail: function (v_email) {

      var result;

      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      console.log("MAIL: ", v_email);

      if (v_email != undefined) {

        if (v_email != "") {

          if (v_email.match(validRegex)) {
            result = true;
          }
          else {
            result = false;

          }


        }
        else {

          result = true;

        }


      }
      else {
        result = true;

      }


      return result;

    },

    validate: function () {

      var that = this;

      var errore = "";

      console.log("FIRST NAME: ", $("#firstName").val());
      console.log("SURNAME: ", $("#surname").val());
      console.log("EMAIL: ", $("#email").val());
      //     console.log("TERM OF USE: ", $(".termOfUse").is(":checked"));

      if ($("#firstName").val() == "") {
        errore = "First Name Required !";
      }

      if (errore == "") {
        if ($("#surname").val() == "") {
          errore = "Surname Required !";
        }
      }

      if (errore == "") {
        if ($("#email").val() == "") {
          errore = "E-Mail Required !";
        }
      }

      if (errore == "") {
        if ($("#email").val() != "") {

          if (!this.validateEMail($("#email").val())) {

            errore = "E-Mail Not Valid !";

          }

        }
      }


      if (errore == "") {

        if ($('#attachment')[0].files.length == 0) {

          errore = "Attachment Required !";

        }

      }

      if (errore == "") {

        if ($('#attachment')[0].files.length > 0) {

          var myfile = $('#attachment')[0].files[0];

          var lfile = myfile.name.split(".");

          console.log("MYFILE: ", lfile[lfile.length - 1]);

          var extension = lfile[lfile.length - 1]

          console.log("EXT: ", extension);

          if ((extension.toString().toUpperCase() != "PDF") && (extension.toString().toUpperCase() != "DOC") && (extension.toString().toUpperCase() != "DOCX")) {

            errore = "Accettati solo file pdf, doc, docx";

          }


        }

      }



      if (errore == "") {

        console.log("ok");

        that.getOtpSendCV();

      }
      else {

        that.$swal({
          icon: "error",
          text: errore,
          showConfirmButton: false,
          timer: 2000
        });


      }



    },


    getWindowMobile: function () {

      if (window.innerWidth <= 768) {

        return true;

      }
      else {

        return false;

      }

    },


    getWindowDesktop: function () {

      if (window.innerWidth > 768) {

        return true;

      }
      else {

        return false;

      }

    },


    manageTabs: function () {

      if (window.innerWidth <= 768) {

        $("#vTabs").removeClass("v-tabs--right");
        $("#vTabs").addClass("v-tabs--centered");

      }
      else {

        $("#vTabs").addClass("v-tabs--right");
        $("#vTabs").removeClass("v-tabs--centered");

      }
    },

    getClass() {

      console.log("WIND WID: ", window.innerWidth);

      if (window.innerWidth <= 768) {

        return 'center-active centered'
      }


      else {
        return 'right'
      }

    },

    changeTabOld: function (v_tab) {

      console.log("TAB:", v_tab);

      if (v_tab == "Functional Area") {


        setTimeout(() => {

          $("#tbFunctionalArea").removeClass("cl-tab");
          $("#tbFunctionalArea").addClass("cl-tab-select");

          $("#tbIndustries").removeClass("cl-tab-select");
          $("#tbIndustries").addClass("cl-tab");

          $("#tbCities").removeClass("cl-tab-select");
          $("#tbCities").addClass("cl-tab");


        }, 200);


      }



      if (v_tab == "Cities") {


        setTimeout(() => {

          $("#idTabCities").empty();

          // browse job by cities
          var v_tot_cities = this.adsCities;

          for (var x = 0; x < v_tot_cities.length; x++) {

            var txt = `<li class="col-md-4 col-sm-6"><a style="font-size:14px;font-family: 'Orbitron', sans-serif!important;"" title=""` + v_tot_cities[x].nome + `"">` + v_tot_cities[x].nome + ` <span>(` + v_tot_cities[x].v_tot + `)</span></a></li>`;

            $("#idTabCities").append(txt);


            $("#tbFunctionalArea").removeClass("cl-tab-select");
            $("#tbFunctionalArea").addClass("cl-tab");

            $("#tbIndustries").removeClass("cl-tab-select");
            $("#tbIndustries").addClass("cl-tab");

            $("#tbCities").removeClass("cl-tab");
            $("#tbCities").addClass("cl-tab-select");


          }


        }, 200);



      }

      if (v_tab == "Industries") {


        setTimeout(() => {

          $("#idTabIndustries").empty();

          // browse job by cities
          var v_tot_industries = this.adsIndustries;

          for (var x = 0; x < v_tot_industries.length; x++) {

            var txt = `<li class="col-md-4 col-sm-6"><a style="font-size:14px;font-family: 'Orbitron', sans-serif!important;" title="` + v_tot_industries[x].v_nome + `">` + v_tot_industries[x].v_nome + ` <span>(` + v_tot_industries[x].v_tot + `)</span></a></li>`;

            $("#idTabIndustries").append(txt);



            $("#tbFunctionalArea").removeClass("cl-tab-select");
            $("#tbFunctionalArea").addClass("cl-tab");

            $("#tbCities").removeClass("cl-tab-select");
            $("#tbCities").addClass("cl-tab");


            $("#tbIndustries").removeClass("cl-tab");
            $("#tbIndustries").addClass("cl-tab-select");


          }


        }, 200);



      }


    },



    setComuni: function (id_regione) {

      console.log("PROV SEL 2: ", id_regione);

      console.log("COMUNI TMP: ", this.comuniTemp);

      this.comuni = [];

      var Comuni = [];

      Comuni.push({
        idcomune: "0",
        idregione: "0",
        nome: "Select City"
      });

      for (var i = 0; i < this.comuniTemp.length; i++) {

        if (parseInt(this.comuniTemp[i].idregione) == parseInt(id_regione)) {

          Comuni.push({
            idcomune: this.comuniTemp[i].idcomune,
            idregione: this.comuniTemp[i].idregione,
            nome: this.comuniTemp[i].nome
          });

        }

      }

      console.log("COMUNI SEL: ", Comuni);

      this.comuni = Comuni;



    },

    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
    ,
    getCookie() {
      this.$cookies.set("cookie-consent-performance", "yes");

      //  this.$swal('You are logged in!!!');

      //   console.log(Vue.cookie.get('token'));

      // it gets the cookie called `username`
      /* const username = this.$cookies.get("username");
      console.log(username); */
    },


    changeTab: function (v_id_tab) {

      setTimeout(() => {


        console.log("ID TAB: ", v_id_tab);

        console.log("SKILL DET: ", this.skillDetails);


        var v_id = "#id" + v_id_tab;


        console.log("ID TAB2: ", v_id);


        $(v_id).empty();


        if (this.skillDetails != null) {


          for (var xx = 0; xx < this.skillDetails.length; xx++) {



            if (v_id_tab != "") {


              if (this.skillDetails[xx].idCat == v_id_tab) {




                console.log("ELEMENTS: ", this.skillDetails[xx].elements);
                console.log("ID CAT: ", this.skillDetails[xx].idCat);


                for (var yy = 0; yy < this.skillDetails[xx].elements.length; yy++) {



                  var v_nome = this.skillDetails[xx].elements[yy].name;
                  var v_logo = "https://app.emtool.eu/public/img/skill/" + this.skillDetails[xx].elements[yy].logo;


                  var txt = "<li class='col-md-4 col-sm-6'><a onclick='return false;'' href='' title='" + v_nome + "'>" + v_nome + " <span>(1)</span></a><div class='imgPreview'><img src='" + v_logo + "' alt='' class='src'></div></li>";

                  console.log("ID TAB3: ", v_id);
                  console.log("TXT TAB3: ", txt);

                  $(v_id).append(txt);



                }






              }








            }







          }






        }


      }, 200);








    },


    getAdsDetails: async function () {

      var that = this;

      var id_ads = window.$cookies.get("id_ads_detail");

      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apisearchads.getAdsDetails(
        id_ads
      ).then((res) => {

        that.$root.$children[0].showProgress = false;
        console.log("res from getAdsDetails", res);


        that.titleAds = res.data.Result.title;
        that.datePosted = res.data.data_enabled;
        that.descLocation = res.data.desc_location;
        that.tipoContratto = res.data.Result.desc_contratto;
        that.careerLevel = res.data.Result.level_work;
        that.yearExperience = res.data.Result.year_experience;
        that.titoloStudio = res.data.Result.desc_studi_minimi;
        that.applyBefore = res.data.apply_before;
        that.adsNumber = res.data.Result.number_ads;


        that.nameAziOverview = res.data.overview_cmp_name;
        that.logoAziOverview = res.data.overview_cmp_logo;

        that.urlSiteCompany = res.data.url_site_azi;

        that.addressCompanyOverview = res.data.str_addr_cmp;

        that.txtNumJobsOpen = res.data.txt_num_jobs;



        that.items = res.data.query_skill_category;

        that.skillDetails = res.data.query_skill_details;



        $("#divTxtAzienda").html(res.data.azienda_overview);





        $(".divJobDesc").html(res.data.Result.description);

        setTimeout(() => {

          if (res.data.idFirstCatSkill != "") {

            $("#" + res.data.idFirstCatSkill).removeClass("cl-tab");
            $("#" + res.data.idFirstCatSkill).addClass("cl-tab-select");

          }


          if (res.data.query_skill_details != null) {


            for (var xx = 0; xx < res.data.query_skill_details.length; xx++) {



              if (res.data.idFirstCatSkill != "") {


                if (res.data.query_skill_details[xx].idCat == res.data.idFirstCatSkill) {




                  console.log("ELEMENTS: ", res.data.query_skill_details[xx].elements);
                  console.log("ID CAT: ", res.data.query_skill_details[xx].idCat);


                  for (var yy = 0; yy < res.data.query_skill_details[xx].elements.length; yy++) {



                    var v_nome = res.data.query_skill_details[xx].elements[yy].name;
                    var v_logo = "https://app.emtool.eu/public/img/skill/" + res.data.query_skill_details[xx].elements[yy].logo;


                    var txt = "<li class='col-md-4 col-sm-6'><a onclick='return false;'' href='' title='" + v_nome + "'>" + v_nome + " <span>(1)</span></a><div class='imgPreview'><img src='" + v_logo + "' alt='' class='src'></div></li>";


                    var v_id = "#id" + res.data.query_skill_details[xx].idCat;

                    $(v_id).append(txt);



                  }






                }








              }







            }






          }




        }, 200);












      }).catch(err => {
        that.$root.$children[0].showProgress = false;
        console.log(err);
        var msg = err.response.data.Error;

        that.$swal({
          icon: "error",
          text: msg,
          showConfirmButton: false,
          timer: 8000
        });
        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

      }

      );




    },








    getValuesSearch: async function () {

      var that = this;

      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apisearchads.getValuesSearch(
        window.urlSiteCareers
      ).then((res) => {

        that.$root.$children[0].showProgress = false;
        console.log("res from getValuesSearch", res);

        that.funcArea = res.data.funcArea;
        that.regioni = res.data.regioni;
        that.comuniTemp = res.data.comuni;

        that.funcAreaSearch = "0";
        that.regioneSearch = "0";

        that.pathLogo = res.data.file_logo;


        window.$cookies.set("url_logo_conf", res.data.file_logo, "9y");

        that.txtPrivacyHome = res.data.txt_privacy_home;

        that.adsCities = res.data.query_tot_cities;

        that.adsIndustries = res.data.query_tot_industries;


        that.txtPrivacy = res.data.txt_privacy;


        that.txtTermOfUse = res.data.txt_term_of_use;


        that.degreeLevel = res.data.filter_degrees;

        // that.provs = res.data.filter_provs;

        that.provsTemp = res.data.filter_provs;


        // browse job by functional area
        var v_tot_func_area = res.data.query_tot_func_area;

        for (var x = 0; x < v_tot_func_area.length; x++) {

          var txt = `<li class="col-md-4 col-sm-6"><a style="font-size:14px;font-family: 'Orbitron', sans-serif!important;"" title=""` + v_tot_func_area[x].description + `"">` + v_tot_func_area[x].description + ` <span>(` + v_tot_func_area[x].v_tot + `)</span></a></li>`;

          $("#idTabFyncArea").append(txt);

        }



        // quick links jobs by functional area
        for (var x2 = 0; x2 < v_tot_func_area.length; x2++) {

          if (x2 <= 2) {

            var txt2 = `<li><a class='clFuncArea' style="font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_func_area[x2].idsubcategory + `' title=""` + v_tot_func_area[x2].description + `"">` + v_tot_func_area[x2].description + `</a></li>`;

            $("#jobsByFuncAreaQuickLinks").append(txt2);

            console.log("ok");


          }


        }



        // quick links jobs by industries

        var v_tot_industries = that.adsIndustries;

        for (var x3 = 0; x3 < v_tot_industries.length; x3++) {

          var txt3 = `<li><a class='clIndustries' style="font-family: 'Orbitron', sans-serif!important;"" data-id='` + v_tot_industries[x3].idcategory + `' title=""` + v_tot_industries[x3].v_nome + `"">` + v_tot_industries[x3].v_nome + `</a></li>`;




          $("#jobsByIndustryQuickLinks").append(txt3);




        }







        $("#tbFunctionalArea").removeClass("cl-tab");
        $("#tbFunctionalArea").addClass("cl-tab-select");

        $(".bttxt").html(res.data.txt_powered_by);
        $(".txtInfoCompany").html(res.data.txt_azi_dx_footer);

        // immagine sfondo header
        if (res.data.file_img_sfondo_header != null) {

          if (res.data.file_img_sfondo_header != "") {

            var val_css = "url('" + res.data.file_img_sfondo_header + "')";
            var prop_css = "background";

            $(".searchwrap").css(prop_css, val_css);

          }

        }


        // immagine sfondo parallax

        $(".userloginbox").css("background", "url('')");



        if (res.data.file_img_sfondo_parallax != null) {

          if (res.data.file_img_sfondo_parallax != "") {

            var val_css2 = "url('" + res.data.file_img_sfondo_parallax + "') no-repeat center";
            var prop_css2 = "background";

            $(".userloginbox").css(prop_css2, val_css2);

            $(".userloginbox").css("background-attachment", "fixed");

            $(".userloginbox").css("background-size", "cover");

            $(".userloginbox").css("padding", "40px 0 120px 0");

            $(".userloginbox").css("text-align", "center");



          }


        }



        // featured job
        $("#divContFeaturedJobs").html(res.data.html_feat_job);
        $("#divContLatestJobs").html(res.data.html_latest_job);



        // immagine sfondo header pagina
        if (res.data.file_img_header_getstart != null) {

          if (res.data.file_img_header_getstart != "") {

            var val_css3 = "url('" + res.data.file_img_header_getstart + "')";
            var prop_css3 = "background-image";

            $(".pageTitle").css(prop_css3, val_css3);

            $(".pageTitle").css("background-size", "cover");

            $(".pageTitle").css("background-repeat", "no-repeat");

            $(".pageTitle").css("background-position", "center");


            // var val_css3 = "url('" + res.data.file_img_header_getstart + "')";
            // var prop_css3 = "background";

            // $(".pageTitle").css(prop_css3, val_css3);

            // $(".pageTitle").css("background-size", "cover");

            // $(".pageTitle").css("padding", "35px 0");


            // if (window.innerWidth <= 768) {

            //   $(".pageTitle").css("min-height", "196px");

            //   $(".pageTitle").css("background-position", "0px -65px");

            // }
            // else {

            //   $(".pageTitle").css("min-height", "281px");

            //   $(".pageTitle").css("background-position", "-1px -812px");

            // }




          }

        }







      }).catch(err => {
        that.$root.$children[0].showProgress = false;
        console.log(err);
        var msg = err.response.data.Error;

        that.$swal({
          icon: "error",
          text: msg,
          showConfirmButton: false,
          timer: 8000
        });
        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

      }

      );




    },

    registerAction: async function () {
      //const auth = { user: this.username, password: this.password };
      // Correct username is 'foo' and password is 'bar'
      //const url = "https://app.emtool.eu/api/emt/register";

      const url = "https://app.emtool.eu/registerUserForCompanyPost";

      //const url = "http://emtool.local/registerUserForCompanyPost";


      this.success = false;
      this.error = null;

      let formData = new FormData();

      formData.append("context", "2recruit");

      if (this.input.username != undefined)
        formData.append("login", this.input.username);

      if (this.input.password != undefined)
        formData.append("password", this.input.password);

      formData.append("companyname", "7");

      if (this.input.name != undefined)
        formData.append("name", this.input.name);

      if (this.input.surname != undefined)
        formData.append("surname", this.input.surname);

      if (this.input.email != undefined)
        formData.append("email", this.input.email);

      if (this.input.phone != undefined)
        formData.append("phone", this.input.phone);

      // if (this.input.namecompany != undefined)
      //   formData.append("namecompany", this.input.namecompany);

      formData.append("app", "2recruit");

      formData.append("is_demo", "S");



      try {
        await this.axios
          .post(url, formData, { "content-type": "text/json" })
          .then((result) => {
            var respo = result.data;
            this.success = true;
            // console.log( respo   );

            setTimeout(() => {
              this.showSpinner = false;

              var msgError = "";
              console.log("re", respo.errors);



              if (respo.errors && !this.isEmpty(respo.errors)) {
                console.log("Errori", respo.errors);
                var err = respo.errors;

                Object.entries(err).forEach(([key, value]) => {
                  console.log(`${key} ${value}`);
                  msgError += `${value}\r\n `;
                });

                if (msgError) {
                  this.$swal({ icon: "error", text: msgError });

                }


              }
              else {
                this.$swal({
                  icon: "success",
                  text: "Please check your email",
                  confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_funads.png' />",
                });

                this.$router.push({
                  path: "/",
                });


              }
              //this.$swal("You are logged in!!!");

              /*     window.$cookies.set("token", respo.token , "9y");
                  window.$cookies.set("system", respo.system,"9y");
                  window.$cookies.set("token_system", respo.token_system,"9y");
                  window.$cookies.set("username", respo.username,"9y");
                  window.$cookies.set("user_id", respo.user_id,"9y");
                  window.$cookies.set("namesurname", respo.namesurname,"9y"); */

              this.$root.$children[0].curDipTop = respo.namesurname;

              //router.push({ path: "/" });

            }, 1);
          });
      } catch (err) {
        this.success = false;
        console.log(err.message);
        this.$swal({ icon: "error", text: "Please check your data" });

        this.showSpinner = false;
        //  console.log(   response);

        //   this.error = err.message;
      }
    },

    register() {
      this.showSpinner = true;
      this.getCookie();

      this.registerAction();
      /*     if (this.input.username != "" && this.input.password != "") {
        if (
          this.input.username == this.$parent.mockAccount.username &&
          this.input.password == this.$parent.mockAccount.password
        ) {
          this.$emit("authenticated", true);
          this.$router.replace({ name: "secure" });
        } else {
          console.log("The username and / or password is incorrect");
        }
      } else {
        console.log("A username and password must be present");
      } */
    },
  },
};
</script>
  
  
<style>
.ads-getstarted .iddegreelevel {
  min-width: 374px;
}


.ads-getstarted .idprov {
  min-width: 374px;
}



.ads-getstarted .clPipe {
  border: 1px solid rgb(102, 102, 102);
  height: 30px;
  margin-top: 20px;
}



.ads-getstarted .clButtonHeader {
  width: 60px !important;
  margin-top: -9px !important;
}

.ads-getstarted .clButtonJobAll {
  width: 31px !important;
  margin-top: 2px !important;
}

.ads-getstarted .clButtonRefresh {
  width: 31px !important;
  margin-top: 2px !important;
}


.v-application ul,
.v-application ol {
  padding-left: 0px !important;
}


.clPrivacy {
  width: 100%;
  overflow-y: auto;
  min-height: 391px;
  max-height: 391px;
  margin-left: 5px;
  margin-top: 5px;
  padding-right: 17px;
}


.clPrivacy h2 {
  font-family: 'Orbitron', sans-serif !important;
}

.clPrivacy p {
  font-family: 'Orbitron', sans-serif !important;
}

.clPrivacy strong {
  font-family: 'Orbitron', sans-serif !important;
  font-weight: bold;
}


.divJobDesc strong {
  font-family: Orbitron !important;
}


.list-default li:before {
  content: '' !important;
}


.job-header div,
.job-header span {
  font-family: Orbitron !important;
  font-size: 14px !important;
}

.detailsJob {
  font-size: 14px;
}

.cl-fj-1 {
  font-family: 'Orbitron', sans-serif !important;
}

.cl-fj-2 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.cl-fj-3 {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.cl-portale-1 {
  font-family: 'Orbitron', sans-serif !important;
  color: white !important;
}


.howlist h4 {
  font-family: 'Orbitron', sans-serif !important;
}

.jobslist li .company {
  color: #999;
  padding: 7px 0;
  padding-top: 0px !important;
}

.titleTop h3 {
  font-family: 'Orbitron', sans-serif !important;
}

.titleTop span {
  font-family: 'Orbitron', sans-serif !important;
}

.navbar-nav {
  flex-direction: inherit !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  list-style: none !important;
}


.bttxt {
  color: #888 !important;
  line-height: 24px !important;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;
}

.bttxt a {
  color: #888 !important;
  font-weight: 600 !important;
}

.address {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.email a {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.phone a {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 14px !important;
}

.footerWrap .email:before {
  position: absolute !important;
  left: 0 !important;
  content: '\f003' !important;
  font-family: 'FontAwesome' !important;
  font-size: 18px !important;
}

ion-select {
  font-size: 18px !important;
  --placeholder-opacity: 1 !important;
  font-family: 'Orbitron', sans-serif !important;
}

.catelist li a:before {
  position: absolute !important;
  left: 0 !important;
  content: '\f0da' !important;
  font-family: 'FontAwesome' !important;
  font-weight: 400 !important;
}

.howlist li .iconcircle {
  text-align: center !important;
  margin-bottom: 15px !important;
  display: inline-block !important;
}

p,
h1,
h2,
h3,
h4,
h5,
body,
.job-header .contentbox p {
  font-family: 'Orbitron', sans-serif !important;
}

.howlist li .iconcircle i {
  font-size: 48px !important;
  color: #444 !important;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.job-header .contentbox h3 i,
.jobdetail h3 i,
.companyinfo h3 i {
  color: #ccc !important;
  margin-right: 5px !important;
  font-size: 20px !important;
}

.fa-user:before {
  content: '\f007' !important;
}

.fa-file-text-o:before {
  content: "\f0f6" !important;
  font-family: 'FontAwesome' !important;
}

.clReadMore {
  font-family: 'Orbitron', sans-serif !important;

}

button {
  font-family: 'Orbitron', sans-serif !important;
}

.select-ios .select-placeholder {
  color: #000;
  font-size: 15px;
  font-family: 'Orbitron', sans-serif !important;
}

.idsubcategory {
  min-width: 521px;
}

.ads-getstarted .idregione {
  min-width: 374px;
}

.companyid {
  min-width: 223px;
}

.cl-tab-select {
  font-size: 16px !important;
  background: #444 !important;
  color: white !important;
  font-family: 'Orbitron', sans-serif !important;
  font-weight: 700 !important;
  padding: 15px 30px !important;
  border-radius: 5px 5px 0 0 !important;
  border-color: #444 !important;
}


.cl-tab {
  font-size: 16px !important;
  background: none !important;
  color: #666 !important;
  font-family: 'Orbitron', sans-serif !important;
  font-weight: 400 !important;
  padding: 15px 30px !important;
}

.clPrivacyHome {
  width: 100%;
  overflow-y: auto;
  min-height: 391px;
  max-height: 391px;
  margin-left: 5px;
  margin-top: 5px;
  padding-right: 17px;
}


.clPrivacyHome h2 {
  font-family: 'Orbitron', sans-serif !important;
}

.clPrivacyHome p {
  font-family: 'Orbitron', sans-serif !important;
}

.clPrivacyHome strong {
  font-family: 'Orbitron', sans-serif !important;
  font-weight: bold;
}




.cl-title-jobsby {}

.v-overflow-bt-padding {
  padding-bottom: 6px !important
}

@media screen and (max-width: 768px) {

  .searchwrap {
    margin-top: 27px !important;
  }

  .idsubcategory {
    min-width: 278px;
  }

  .ads-getstarted .iddegreelevel {
    min-width: 278px;
  }

  .ads-getstarted .idregione {
    min-width: 278px;
  }

  .ads-getstarted .idprov {
    min-width: 278px;
  }

  .companyid {
    min-width: 278px;
  }

  .cl-tab {
    font-size: 1rem !important;
    background: none !important;
    color: #666 !important;
    padding: 8px !important;
    width: 249px !important;
    min-width: 249px !important;
    font-family: 'Orbitron', sans-serif !important;
  }

  .cl-tab-select {
    font-size: 1rem !important;
    background: #444 !important;
    color: white !important;
    padding: 8px !important;
    width: 249px !important;
    min-width: 249px !important;
    font-family: 'Orbitron', sans-serif !important;
  }



  .cl-title-jobsby {
    height: 108px !important;
  }

  .v-overflow-bt-padding {
    padding-bottom: 40px !important
  }

  .bttxt {
    color: #888 !important;
    line-height: 24px !important;
    font-family: 'Orbitron', sans-serif !important;
    font-size: 14px !important;
    display: block;
    align-items: center;
  }

  .bttxt a {
    color: #888 !important;
    font-weight: 600 !important;
  }

  .clPrivacy {
    width: 100%;
    overflow-y: auto;
    min-height: 391px;
    max-height: 391px;
    margin-left: 5px;
    margin-top: 5px;
    padding-right: 17px;
  }

  .ads-getstarted .clButtonHeader {
    min-width: 40px !important;
    margin-top: -6px !important;
  }

  .ads-getstarted .clButtonRefresh {
    min-width: 20px !important;
    margin-top: 1px !important;
  }

  .ads-getstarted .clPipe {
    border: 1px solid rgb(102, 102, 102);
    height: 17px;
    margin-top: 18px;
  }

  .ads-getstarted .clButtonJobAll {
    min-width: 20px !important;
    margin-top: 1px !important;
  }


}
</style>
  
<style scoped>
#qr-code-wrapper,
#lang-select,
#otp-select {
  display: none;
}

#login {
  width: auto;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin: auto;
  /*   margin-top: 200px; */
  padding: 20px;
}

#nav,
.v-sheet {
  display: none;
}
</style>
  
  
  