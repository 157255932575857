import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIProfileCompany {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async getProfileCompany(user) {

        let formData = new FormData();

        formData.append("username", user);
   
        return axios.post(this.getUrlDomain("FunAdsGetProfile"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async setProfileCompany(profile, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in profile) {
            formData.append(key, profile[key]);
        }
   
        return axios.post(this.getUrlDomain("FunAdsSetProfile"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async mailDeleteProfileCompany(user, email) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("email", email);
   
        return axios.post(this.getUrlDomain("FunAdsMailDelProfile"), formData, { 'Content-Type': 'multipart/form-data' });

    }




}

export default new APIProfileCompany ({
    url: "https://services.ebadge.it/public/api/"
})
