import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APISearchAds {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getValuesSearch(url_site) {

    let formData = new FormData();

    formData.append("url_site", url_site);

    return axios.post(this.getUrlDomain("FunAdsSearchAdsGetDDL"), formData, { 'Content-Type': 'multipart/form-data' });

  }







  async getAdsDetails(id) {

    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("CareersGetAdsDetails"), formData, { 'Content-Type': 'multipart/form-data' });

  }


}

export default new APISearchAds({
  url: "https://services.ebadge.it/public/api/"
})
