<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px">
        <v-row>
            <v-col cols="12">
            </v-col>




            <v-col v-if="viewAnnunci == 1" cols="6" class="mb-15">

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon id="no-background-hover" to="/annunci">

                            <v-img alt="Emt logo" width="60" class=" " contain src="@/assets/jobmule_annunci.png"
                                transition="scale-transition" />

                        </v-btn>
                        <div class="btnText">Annunci</div>
                    </template>
                    <span>Annunci</span>
                </v-tooltip>

            </v-col>



        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "HelloWorld",

    mounted() {

        this.setUrlDomainGlobal();

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }


        if (window.urlSiteCareers == "https://funads.eu/") {

            window.context = "funads";


            if (this.isSU == 0) {

                this.viewSlider = this.getEnableConf("slider_jobmule");
                this.viewMailGruppi = this.getEnableConf("gruppi_mail_jobmule");
                this.viewMailInvio = this.getEnableConf("invio_jobmule");
                this.viewAziende = this.getEnableConf("aziende_jobmule");
                this.viewCategorie = this.getEnableConf("categorie_jobmule");
                this.viewGiornataLavoroTipo = this.getEnableConf("giorn_lav_tipo_jobmule");
                this.viewPrivacy = this.getEnableConf("provacy_jobmule");
                this.viewSettore = this.getEnableConf("settore_jobmule");
                this.viewSkills = this.getEnableConf("skills_jobmule");
                this.viewSottoCategorie = this.getEnableConf("subcat_jobmule");
                this.viewStudi = this.getEnableConf("studi_jobmule");
                this.viewTipoContratto = this.getEnableConf("tipo_contr_jobmule");
                this.viewProfilo = this.getEnableConf("profilo_jobmule");
                this.viewComuni = this.getEnableConf("comuni_jobmule");
                this.viewProvince = this.getEnableConf("province_jobmule");
                this.viewRegioni = this.getEnableConf("regioni_jobmule");
                this.viewStatiIter = this.getEnableConf("stati_iter_jobmule");
                this.viewAnnunci = this.getEnableConf("annunci_jobmule");
                this.viewCandidati = this.getEnableConf("candidati_jobmule");
                this.viewCVRicevuti = this.getEnableConf("cv_ric_jobmule");
                this.viewReferenti = this.getEnableConf("refer_jobmule");
                this.viewRicerca = this.getEnableConf("ricerca_jobmule");
                this.viewLogoPortale = this.getEnableConf("logo_jobmule");
                this.viewImmaginiSfondoPortale = this.getEnableConf("imgs_sofndo_jobmule");
                this.viewAziendaPortale = this.getEnableConf("azienda_jobmule");
                this.viewRSSPortale = this.getEnableConf("rss_jobmule");
                this.viewPoweredBy = this.getEnableConf("power_by_jobmule");
                this.viewPrivacyPortale = this.getEnableConf("privacy_portale_jobmule");
                this.viewTermOfUse = this.getEnableConf("term_of_use_jobmule");
                this.viewCVCandidato = this.getEnableConf("cv_personali");

            }
            else {


                this.viewSlider = 1;
                this.viewMailGruppi = 1;
                this.viewMailInvio = 1;
                this.viewAziende = 1;
                this.viewCategorie = 1;
                this.viewGiornataLavoroTipo = 1;
                this.viewPrivacy = 1;
                this.viewSettore = 1;
                this.viewSkills = 1;
                this.viewSottoCategorie = 1;
                this.viewStudi = 1;
                this.viewTipoContratto = 1;
                this.viewProfilo = 1;
                this.viewComuni = 1;
                this.viewProvince = 1;
                this.viewRegioni = 1;
                this.viewStatiIter = 1;
                this.viewAnnunci = 1;
                this.viewCandidati = 1;
                this.viewCVRicevuti = 1;
                this.viewReferenti = 1;
                this.viewRicerca = 1;
                this.viewCVCandidato = 1;
                this.viewLogoPortaleJobmule = 1;
                this.viewImmaginiSfondoPortaleJobmule = 1;
                this.viewAziendaPortaleJobmule = 1;
                this.viewRSSPortaleJobmule = 1;
                this.viewPoweredByJobmule = 1;
                this.viewPrivacyPortaleJobmule = 1;
                this.viewTermOfUseJobmule = 1;


            }





        }
        else {

            window.context = "careers";


            if (this.isSU == 1) {

                this.viewLogoPortale = 1;
                this.viewImmaginiSfondoPortale = 1;
                this.viewAziendaPortale = 1;
                this.viewRSSPortale = 1;
                this.viewPoweredBy = 1;
                this.viewPrivacyPortale = 1;
                this.viewTermOfUse = 1;

            }


        }





        this.$root.$children[0].loadLogo();


    },

    methods: {

        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            var elements = v_product_permissions.split("|");

            for (var x = 0; x <= elements.length - 1; x++) {

                if (elements[x] == v_codice) {

                    result = 1;

                }


            }


            return result;

        },




        setUrlDomainGlobal: function () {

            let result = "";

            if (window.siteForTest == "") {

                if (location.hostname == "localhost") {
                    result = location.protocol + "//emtool.local/";
                }

                if (location.hostname != "localhost") {

                    result = location.protocol + "//" + location.hostname + "/";

                }

            }
            else {

                result = window.siteForTest;

            }



            window.urlSiteCareers = result;

        },


    },

    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },
    data: () => ({

        viewSlider: 0,
        viewMailGruppi: 0,
        viewMailInvio: 0,
        viewAziende: 0,
        viewCategorie: 0,
        viewGiornataLavoroTipo: 0,
        viewPrivacy: 0,
        viewSettore: 0,
        viewSkills: 0,
        viewSottoCategorie: 0,
        viewStudi: 0,
        viewTipoContratto: 0,
        viewProfilo: 0,
        viewComuni: 0,
        viewProvince: 0,
        viewRegioni: 0,
        viewStatiIter: 0,
        viewAnnunci: 0,
        viewCandidati: 0,
        viewCVRicevuti: 0,
        viewReferenti: 0,
        viewRicerca: 0,
        viewLogoPortale: 0,
        viewImmaginiSfondoPortale: 0,
        viewAziendaPortale: 0,
        viewRSSPortale: 0,
        viewPoweredBy: 0,
        viewPrivacyPortale: 0,
        viewTermOfUse: 0,
        viewLogoPortaleJobmule: 0,
        viewImmaginiSfondoPortaleJobmule: 0,
        viewAziendaPortaleJobmule: 0,
        viewRSSPortaleJobmule: 0,
        viewPoweredByJobmule: 0,
        viewPrivacyPortaleJobmule: 0,
        viewTermOfUseJobmule: 0,
        viewCVCandidato: 0,


        isSU: 0,
        isAdmin: 0,

        viewGroupMail: 0,
        viewConfInvioMail: 0,
        viewTipologie: 0,
        viewPagamenti: 0,
   

        ecosystem: [{
            text: "vuetify-loader",
            href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
            text: "github",
            href: "https://github.com/vuetifyjs/vuetify",
        },
        {
            text: "awesome-vuetify",
            href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
        ],
        importantLinks: [{
            text: "Documentation",
            href: "https://vuetifyjs.com",
        },
        {
            text: "Chat",
            href: "https://community.vuetifyjs.com",
        },
        {
            text: "Made with Vuetify",
            href: "https://madewithvuejs.com/vuetify",
        },
        {
            text: "Twitter",
            href: "https://twitter.com/vuetifyjs",
        },
        {
            text: "Articles",
            href: "https://medium.com/vuetify",
        },
        ],
        whatsNext: [{
            text: "Explore components",
            href: "https://vuetifyjs.com/components/api-explorer",
        },
        {
            text: "Select a layout",
            href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
        },
        {
            text: "Frequently Asked Questions",
            href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
        },
        ],
    }),
};
</script>

<style>
.btnText {
    padding-top: 20px;
    visibility: hidden;
}
</style>
