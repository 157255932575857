<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center"
        style="max-width: 300px; min-width: 300px">

        <div class="Absolute-Center">

        </div>

    </v-container>
</template>

<script>
import {
    bus
} from "../main";
import router from ".././router";
import $ from 'jquery';
import apigroups from "../utils/groups/apigroups";

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];
        bus.$off("bottomIconClickEvent");
        this.$root.$children[0].showProgress = false;
    },

    mounted: async function () {

        this.setUrlDomainGlobal();


        window.portale = "careers";
        if (window.urlSiteCareers == "https://funads.eu/") {
            window.portale = "funads";
        }

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }



        if (window.portale == "funads") {

            if ((this.isSU == 1) || (this.isAdmin == 1)) {

                router.push({
                    path: "/filterGroupsAdmin"
                });

            }
            else {

                setTimeout(() => {

                    this.syncAziende();

                }, 100);

            }

        }

        if (window.portale == "careers") {

            if ((this.isSU == 1) || (this.isAdmin == 1)) {



                this.setAziendaCareers();



            }


        }





    },



    data: () => ({
        showPicker: false,

        isAdmin: 0,
        isSU: 0,

    }),

    methods: {

        setUrlDomainGlobal: function () {

            let result = "";

            if (window.siteForTest == "") {

                if (location.hostname == "localhost") {
                    result = location.protocol + "//emtool.local/";
                }

                if (location.hostname != "localhost") {

                    result = location.protocol + "//" + location.hostname + "/";

                }

            }
            else {

                result = window.siteForTest;

            }



            window.urlSiteCareers = result;

        },


        setAziendaCareers: async function () {

            var that = this;

            //var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apigroups.getAziendaCareersGlobalJM(
                window.urlSiteCareers
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziendeGlobalJM", res);


                window.$cookies.set("sel_filter_azienda_gruppi", res.data.Result, "9y");

                router.push({
                    path: "/groupsAzienda"
                });


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },



        syncAziende: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apigroups.getAziendeGlobalJM(
                v_token,
                window.portale
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAziendeGlobalJM", res);


                window.$cookies.set("sel_filter_azienda_gruppi", res.data.aziende[0].companyid, "9y");

                router.push({
                    path: "/groupsAzienda"
                });


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        getUrlDomainSimple: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        fixBkc() {

            var settSmartbook = JSON.parse(window.$cookies.get("settSmartbook"));
            var settings = settSmartbook[0];
            var backgroundImage = settings.backgroundImage;
            var txt_image = this.getUrlDomainSimple("public/bck_companies/" + backgroundImage);

            var rule = ` .bkc_over_image {background-image :url(` + txt_image + `)!important}`;
            $('#addedCSS').text(rule);

        },

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "api/" + nome_metodo;

        },

        getUrlDomainBase: function (nome_metodo) {
            let result = "";
            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }
            if ($(location).attr('hostname') != "localhost") {
                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";
            }
            return result + "" + nome_metodo;
        },
        setupButtons: async function () {
            setTimeout(() => {

                // this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {

        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

    },
};
</script>
