<template>
    <div class="mainWrap">
        <!-- <frontslider table="2recruit_settings" app="2recruit" ref="slidercomp" style="display:block;"></frontslider> -->


        <div id="logins">

            <link href="https://fonts.googleapis.com/css?family=Roboto:100,400,800" rel="stylesheet" />
            <!--  <h1>Login</h1>
        <input type="text" name="username" v-model="input.username" placeholder="Username" />
        <input type="password" name="password" v-model="input.password" placeholder="Password" />
        <button type="button" v-on:click="login()">Login</button> -->

            <div class="fs-container">
                <div class="fs-container__bg"></div>
                <div class="content">
                    <div class="content-login">
                        <div class="content__pane init2">

                            <img class="logo" :src="pathLogo" style="max-width: 100px" />

                            <form name="login" id="login-form" novalidate="" method="POST" v-on:submit.prevent
                                style="max-width: 286px;">
                                <div class="flex-container">
                                    <input type="hidden" id="t_login" name="t_login" value="NO_QRCODE" />

                                    <div id="classic-login">
                                        <div class="group filled">
                                            <input v-model="input.email" id="user" class="user" required="" name="user"
                                                type="text" value="" placeholder="E-Mail" />

                                            <span class="highlight"></span>
                                            <span class="bar"></span>
                                            <label>
                                                <img src="@/assets/loginstart/img/mail.png" />
                                                <span class="label__text label__text--hidden">E-Mail</span>
                                            </label>
                                        </div>


                                    </div>

                                    <div id="qr-code-wrapper">
                                        <button type="button" id="qr-code-btn">
                                            <span class="tooltiptext">Login with QR-Code</span>

                                            <img src="@/assets/loginstart/img/qr-btn-alpha.png" alt="submit" />
                                        </button>
                                    </div>

                                    <!-- added code -->

                                    <div class="container" id="qr-login" style="display: none">
                                        <div>
                                            <a class="button" id="resetButton">Back</a>
                                        </div>

                                        <div>
                                            <video id="video" width="300" height="200"
                                                style="border: 1px solid gray"></video>
                                        </div>

                                        <div id="sourceSelectPanel" style="display: none">
                                            <label for="sourceSelect">Change video source:</label>
                                            <select id="sourceSelect" style="max-width: 400px"></select>
                                        </div>

                                        <div style="text-align: left; display: none">
                                            <label>Result:</label>
                                            <pre><code id="result"></code></pre>
                                        </div>
                                    </div>

                                    <!-- end added code -->
                                </div>

                                <div id="lang-select" class="group select">
                                    <div class="lang__field">
                                        <span class="lang__selected"><img src="@/assets/loginstart/img/uk.png"
                                                class="flag-img" /></span>
                                        <label class="select__label">
                                            <img src="@/assets/loginstart/img/language.png" />
                                        </label>
                                        <div class="lang__options">
                                            <div data-value="EN" class="lang__option">
                                                <img src="@/assets/loginstart/img/uk.png" class="flag-img" />
                                            </div>
                                            <div data-value="IT" class="lang__option">
                                                <img src="@/assets/loginstart/img/italy.png" class="flag-img" />
                                            </div>
                                        </div>
                                    </div>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                </div>

                                <div id="otp-select" class="group select">
                                    <div class="otp__field">
                                        <span class="otp__selected">
                                            <div class="otp-text">Email</div>
                                            <img src="@/assets/loginstart/img/mail.png" class="otp-img" />
                                        </span>
                                        <label class="select__label">
                                            <div class="text-label">OTP</div>
                                            <img src="@/assets/loginstart/img/token.png" />
                                        </label>
                                        <div class="otp__options">
                                            <div data-value="MAIL" class="otp__option">
                                                <div class="otp-text">Email</div>
                                                <img src="@/assets/loginstart/img/mail.png" class="otp-img" />
                                            </div>
                                            <div data-value="SMS" class="otp__option">
                                                <div class="otp-text">Sms</div>
                                                <img src="@/assets/loginstart/img/sms.png" class="otp-img" />
                                            </div>

                                            <div data-value="Auth" class="otp__option">
                                                <div class="otp-text">Auth</div>
                                                <img src="@/assets/loginstart/img/auth.png" class="otp-img" />
                                            </div>
                                        </div>
                                    </div>
                                    <span class="highlight"></span>
                                    <span class="bar"></span>
                                </div>

                                <button class="
                                    button button--block button--outline button--primary
                                    modal-trigger clButtonLogin
                                                                                                        "
                                    id="login-button" value="login" v-on:click="login()">
                                    <div class="button--text">Login</div>
                                    <div class="spinner hidden">
                                        <div class="holder">
                                            <div class="preloader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </button>

                                <img src="@/assets/back-funads.png" class="clBtnBack" id="btnBackCareers" title="Back"
                                    @click="goBack()" />


                                <rise-loader v-if="showSpinner" :size="'20px'" :color="'#14259b'" />
                                <!-- <div class="info info--error info--hidden">
                Devi inserire sia il nome utente sia la password
              </div> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <div class="contpopupotplogin" style="display:none">

            <template>
                <modal name="popupOtpLogin" :clickToClose="false" :width="262" :height="221">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px;font-size: 12px!important;">

                            Codice OTP inviatoLe via mail

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field type="password" v-model="valueOtpLogin" label="" id="fldValueOtpLogin">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row v-if="showResend">

                        <v-col cols="12" md="12" style="display: flex;
                                        justify-content: center;
                                        padding-top: 0px!important;
                                        margin-top: 0px!important;
                                        font-size: 12px!important">

                            <div>OTP non ricevuto ? </div>
                            <div style="font-weight: bold;
                                        margin-left: 10px;
                                        cursor: pointer;" class="clReinviaOtp">Reinvia</div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-login" contain src="@/assets/btn_cancel3_careers.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" @click="btnCancelOtpLogin" />

                                <v-img alt="" class="btn-confirm-otp-login" contain src="@/assets/btn_confirm3_careers.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" @click="btnConfirmOtpLogin" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>






    </div>
</template>

<style lang="css" scoped>
@import "./../assets/loginstart/style.css";
</style>

<script>
//import fslider from "../../../vuecommon/background.vue"

// import frontslider from "../../../vuecommon/frontslider";



import $ from 'jquery';
import RiseLoader from "vue-spinner/src/RiseLoader.vue";

import router from ".././router";


import apilogincandidate from "../utils/logincandidate/apilogincandidate";
import apiotp from "../utils/otp/apiotp";


/* import Vue from 'vue' */
//import App from '.././App.vue'

export default {
    // components: {
    //     /* PulseLoader, */
    //     RiseLoader, frontslider
    // },

    components: {
        /* PulseLoader, */
        RiseLoader
    },

    updated() { },

    mountedOLD: function () {
        console.log(this.$route.path);
        if (this.$route.path == "/") {
            // console.log( this.$parent );
            //  window.App.ok = 1 ;
            //   App.hideToolbars = true ;
            // Vue.set(this.$root , 'hideToolbars', true)
        } else {
            // window.App.ok = 0 ;
            //  App.hideToolbars = false ;
            // this.$root.hideToolbars = false ;
            // Vue.set(this.$root , 'hideToolbars', false)
        }
    },
    mounted: function () {



        this.setUrlDomainGlobal();

        window.sendOtp = 1;


        window.myVal = "";


        var loggedIn = this.$cookies.get('token');

        if ((location.hostname == "testheyjob.emtool.eu") || (location.hostname == "presenze.stipendio.net")) {
            this.showEmt = false;
            this.showNext3 = true;
        }



        if (loggedIn) {
            router.push({
                path: "/dash"
            });
        }
        console.log("Ciao: " + this.userLogged);


        this.pathLogo = window.$cookies.get("url_logo_conf");

        console.log("URL LOGO: ", this.pathLogo);

        var pointerVue = this;

        $(document).ready(function () {


            $('body').on('click', '.clReinviaOtp', function (event) {
                event.preventDefault();

                pointerVue.showResend = false;

                pointerVue.$modal.hide('popupOtpLogin');

                window.sendOtp = 1;


                setTimeout(() => {

                    pointerVue.getOtpLogin();

                }, 300);


            });



        });



        //        this.$cookie.get('jwt_token')
        //console.log ( this.$cookie.getAll() );
        //$cookie.set('cookie_name', 'cookie_value');
        //window.$cookie.set('test', 'Hello world!', 1);
        //  console.log (window.$cookies.getCookie()  );
        // const username = this.$cookies.get("username");
        //console.log(username);
        //        this.$cookie.getAll()
    },
    name: "Login",

    // data:

    data() {
        return {

            showResend: true,

            valueOtpLogin: "",


            pathLogo: "",

            dialogRegister: true,

            showEmt: true,
            showNext3: false,

            notifications: false,
            sound: true,
            widgets: false,

            showSpinner: false,
            input: {
                username: "",
                password: "",
            },
        };
    },
    methods: {

        getUrlLoginApi: function () {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/utility/checkLoginJM.php";

        },



        setUrlDomainGlobal: function () {

            let result = "";

            if (window.siteForTest == "") {

                if (location.hostname == "localhost") {
                    result = location.protocol + "//emtool.local/";
                }

                if (location.hostname != "localhost") {

                    result = location.protocol + "//" + location.hostname + "/";

                }

            }
            else {

                result = window.siteForTest;

            }



            window.urlSiteCareers = result;

        },


        btnCancelOtpLogin: function () {

            this.$modal.hide('popupOtpLogin');


        },

        btnConfirmOtpLogin: function () {


            if (this.valueOtpLogin != "") {

                console.log("OTP INP: ", this.valueOtpLogin);
                console.log("ENC OTP INP: ", btoa(this.valueOtpLogin));

                if (window.myVal == btoa(this.valueOtpLogin)) {

                    this.$modal.hide('popupOtpLogin');

                    var username = atob(window.$cookies.get("token"));
                    var pswd = atob(window.$cookies.get("code"));

                    this.loginAction2(username, pswd);


                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }
            else {

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },



        btnConfirmOtpLoginOld: function () {


            if (this.valueOtpLogin != "") {

                console.log("OTP INP: ", this.valueOtpLogin);
                console.log("ENC OTP INP: ", btoa(this.valueOtpLogin));

                if (window.myVal == btoa(this.valueOtpLogin)) {

                    this.$modal.hide('popupOtpLogin');

                    setTimeout(() => {

                        router.push({
                            path: "/CvCandidato"
                        });

                    }, 300);


                }
                else {

                    this.$swal({
                        icon: "error",
                        text: "Valore OTP non corretto",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }
            else {

                this.$swal({
                    icon: "error",
                    text: "Specificare un  valore dell'OTP",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },




        getOtpLogin: async function () {

            var that = this;


            window.myVal = "";


            if (window.sendOtp == 1) {

                window.sendOtp = 0;

                var v_company_id = 7;

                if (window.urlSiteCareers == "https://careers.ariall.eu/") {
                    v_company_id = 8;
                }


                that.showSpinner = true;
                // that.$root.$children[0].showProgress = true;

                var response = await apiotp.generateOTPAndSendMailLogin(
                    this.input.email,
                    'careers',
                    'login_candidati',
                    v_company_id
                ).then((res) => {

                    that.showSpinner = false;
                    //  that.$root.$children[0].showProgress = false;
                    console.log("res from generateOTPAndSendMailLogin", res);

                    window.myVal = res.data.Result;


                    setTimeout(() => {

                        that.showResend = true;

                        that.valueOtpLogin = "";

                        $(".contpopupotplogin").show();

                        this.$modal.show('popupOtpLogin');

                        setTimeout(() => {

                            $("#fldValueOtpLogin").focus();

                        }, 300);


                    }, 200);





                }).catch(err => {
                    that.showSpinner = false;
                    // that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("Errori", "Non è stato possibile eliminare i dati");
                    console.log("response", response);

                }

                );



            }









        },


        goBack: function () {

            console.log("GO BACK");

            this.$root.$children[0].logout();

            var v_page = window.$cookies.get("page_go_back");

            console.log("PAGE CALL: ", v_page);

            router.push({ path: '/' + v_page }).catch(() => { });


        },

        showPassword() {

            var currPass = this.$refs.currPass;
            if (currPass.type === "password") {
                currPass.type = "text"
                currPass.btnText = "Hide Password"
            } else {
                currPass.type = "password"
                currPass.btnText = "Show Password"
            }
        },


        getCookie() {
            this.$cookies.set("cookie-consent-performance", "yes");

            //  this.$swal('You are logged in!!!');

            //   console.log(Vue.cookie.get('token'));

            // it gets the cookie called `username`
            /* const username = this.$cookies.get("username");
            console.log(username); */
        },

        loginAction: async function () {

            var that = this;

            that.showSpinner = true;
            //that.$root.$children[0].showProgress = true;

            var response = await apilogincandidate.loginActionCareers(
                this.input.email,
                "candidato"
            ).then(async (res) => {


                console.log("res from loginActionCareers", res);

                if (res.data.Result == "OK") {

                    window.$cookies.set("a", res.data.Respo.a, "9y");
                    window.$cookies.set("b", res.data.Respo.b, "9y");
                    window.$cookies.set("token", res.data.Respo.token, "9y");
                    window.$cookies.set("namesurname", res.data.Respo.namesurname, "9y");
                    window.$cookies.set("code", res.data.Respo.code, "9y");

                    // console.log("TOKEN: ", atob(res.data.Respo.token));
                    // console.log("CODE: ", atob(res.data.Respo.code));



                    that.getOtpLogin();

                }
                else {

                    that.showSpinner = false;
                    //that.$root.$children[0].showProgress = false;

                    that.$swal({
                        icon: "error",
                        text: "E-Mail non trovata",
                        showConfirmButton: false,
                        timer: 3000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );




        },


        loginAction2: async function (v_username, v_password) {
            //const auth = { user: this.username, password: this.password };
            // Correct username is 'foo' and password is 'bar'
            //const url = "https://app.emtool.eu/api/emt/checkLogin";


            this.showSpinner = true;

            const url = this.getUrlLoginApi();

            this.success = false;
            this.error = null;

            let formData = new FormData();

            // formData.append("user", this.input.username);
            // formData.append("password", this.input.password);

            formData.append("user", v_username);
            formData.append("password", v_password);
            formData.append("productid", "Jobmule");
            formData.append("soggetto", "candidato");
            formData.append("portale", "careers");

            var login = this.input.username;
            try {
                await this.axios
                    .post(url, formData, {
                        "content-type": "application/json"
                    })
                    .then((result) => {
                        var respo = result.data;
                        this.success = true;
                        console.log("RESPO: ", respo);

                        if (respo.Result == "OK") {

                            if (respo.product_enable == 1) { // prodotto abilitato

                                setTimeout(() => {

                                    this.showSpinner = false;
                                    //this.$swal("You are logged in!!!");

                                    window.$cookies.set("a", respo.a, "9y");
                                    window.$cookies.set("b", respo.b, "9y");


                                    window.$cookies.set("token", respo.token, "9y");
                                    window.$cookies.set("system", respo.system, "9y");
                                    window.$cookies.set("token_system", respo.token_system, "9y");
                                    window.$cookies.set("username", respo.username, "9y");
                                    window.$cookies.set("user_id", respo.user_id, "9y");
                                    window.$cookies.set("namesurname", respo.namesurname, "9y");
                                    window.$cookies.set("is_ebadge", respo.is_ebadge, "9y");
                                    window.$cookies.set("is_cust_supp", respo.isCustOrSupplier.length, "9y");
                                    window.$cookies.set("is_agila", respo.is_agila, "9y");
                                    window.$cookies.set("models", respo.models, "9y");
                                    window.$cookies.set("product_permissions", respo.product_permissions, "9y");
                                    window.$cookies.set("cid", respo.cid, "9y");
                                    window.$cookies.set("is_invite_cespiti", 0, "9y");
                                    window.$cookies.set("companyid_rich_hol", "", "9y");
                                    window.$cookies.set("resourceid_rich_hol", "", "9y");
                                    window.$cookies.set("idrichesta_rich_hol", "", "9y");
                                    window.$cookies.set("sel_filter_idrichiesta_storico_ferie", "", "9y");
                                    window.$cookies.set("tipo_utente", "Candidato", "9y");

                                    var uname = login;
                                    const args = [uname, true, ['bar', 5], {
                                        foo: 'baz'
                                    }];
                                    try {
                                        console.log("entering user");
                                        window.flutter_inappwebview.callHandler('setLoggedUser', ...args);

                                    } catch (error) {
                                        // alert(error);
                                    }

                                    this.$root.$children[0].curDipTop = respo.namesurname;

                                    //update menu after login
                                    this.$root.$children[0].changeMenu();
                                    //Load companies array
                                    this.$root.$children[0].syncMonth();
                                    this.$root.$children[0].syncGroups();

                                    this.$root.$children[0].refreshMenu();


                                    setTimeout(() => {
                                        router.push({
                                            path: "/dash"
                                        });

                                    }, 300);






                                }, 1);

                            }
                            else {

                                this.$swal({
                                    icon: "error",
                                    text: "Prodotto non abilitato",
                                    showConfirmButton: false,
                                    timer: 3000
                                });

                                this.showSpinner = false;

                            }


                        }
                        else {

                            this.success = false;
                            this.$swal({
                                icon: "error",
                                text: respo.ErrDetails,
                                confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_careers.png' />",
                                cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_careers.png' />",
                                showCancelButton: false,
                                customClass: {
                                    cancelButton: 'order-1',
                                    confirmButton: 'order-2',
                                }
                            });

                            this.showSpinner = false;

                        }




                    });
            } catch (err) {
                this.success = false;
                console.log(err.message);
                this.$swal({
                    icon: "error",
                    text: "Credenziali sbagliate",
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_careers.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_careers.png' />",
                });

                this.showSpinner = false;
                //  console.log(   response);

                //   this.error = err.message;
            }
        },






        login() {
            this.showSpinner = true;
            this.getCookie();
            this.loginAction();


        },
    },
};
</script>

<style scoped>
#qr-code-wrapper,
#lang-select,
#otp-select {
    display: none;
}

#login {
    width: auto;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    margin: auto;
    /*   margin-top: 200px; */
    padding: 20px;
}

#nav,
.v-sheet {
    display: none;
}

img.showpwd {
    position: absolute;
    /* right: 0px; */
    top: 14px;
    right: 0px;
    max-width: 22px;
    cursor: pointer;

}
</style>


<style>
.v-text-field input {
    font-size: 12px !important;
}


.button.clButtonLogin:after {
    background-color: #14259b !important;
}



::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c8d4da;
    opacity: 1;
    /* Firefox */
}



.clBtnBack {
    width: 40px;
    margin-top: 45px;
    cursor: pointer;
}
</style>