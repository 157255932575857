<template>
    <div class="cont-table-ads-categorie">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>



        <div class="row cont_ads_categorie">
            <div class=" " style="margin:0 auto;">
                <div class="card" style="border: 0px!important;">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall'
                                                    style="width:10px!important;height:10px!important" class='checkall'
                                                    slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>


                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>
                                        <th class='all'>Inserito Il</th>
                                        <th class='all'>E-Mail</th>


                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="4">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetChangeStatus">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetChangeStatus = !sheetChangeStatus">
                        <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_funads.png" max-width="30"
                            max-height="30" transition="scale-transition" style="margin-right:20px!important" />
                    </v-btn>
                    <div class="py-3">
                        Cambia stato in
                    </div>
                    <v-list-item v-for="tile in allStatus" :key="tile.value" @click="changeStatus(tile.value)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.description
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="text-left">
            <v-bottom-sheet v-model="sheetFilterStatus">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetFilterStatus = !sheetFilterStatus">
                        <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_funads.png" max-width="30"
                            max-height="30" transition="scale-transition" style="margin-right:20px!important" />
                    </v-btn>
                    <div class="py-3">
                        Filtro stato
                    </div>
                    <v-list-item v-for="tile in allFilterStatus" :key="tile.value" @click="changeFilterStatus(tile.value)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.description
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="contpopuphistory" style="display:none">

            <template>
                <modal name="popupHistory" :clickToClose="false" :width="350" :height="400">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Storico Clienti

                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12">

                            <table style="margin-left:11px">
                                <thead>
                                    <tr>
                                        <th style="padding:5px 5px 5px 5px;width:95px">Nome</th>
                                        <th style="padding:5px 5px 5px 5px;width:95px">Referente</th>
                                        <th style="padding:5px 5px 5px 5px">Data</th>
                                        <th style="padding:5px 5px 5px 5px">Stato</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="cust in customers_history" :key="cust.id">
                                        <td style="padding:5px 5px 5px 5px;width:95px">{{ cust.fld_name }}</td>
                                        <td style="padding:5px 5px 5px 5px;vertical-align:top;width:95px">
                                            {{ cust.referente }}</td>
                                        <td style="padding:5px 5px 5px 5px;vertical-align:top">{{ cust.data }}</td>
                                        <td style="padding:5px 5px 5px 5px;vertical-align:top">{{ cust.description }}</td>
                                    </tr>
                                </tbody>



                            </table>





                        </v-col>


                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-history" contain src="@/assets/btn_cancel3.png"
                                    max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;transform:translateY(218px)"
                                    transition="scale-transition" title="Annulla" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/jobmule_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="" style="font-size:11px;color:black;font-weight:bold">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_funads.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Annulla" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_funads.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupmail" style="display:none">

            <template>
                <modal name="popupMail" :clickToClose="false" :width="300" :height="500">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Anteprima Mail

                        </v-col>

                    </v-row>

                    <v-row style="margin-left:5px!important">

                        <v-col cols="12" md="12">

                            <textarea id="sec_body" name="sec_body" class="sec_body" autofocus
                                style="width:270px!important;height:400px!important"></textarea>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-mail" contain src="@/assets/btn_cancel3_funads.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:20px"
                                    transition="scale-transition" title="Annulla" />

                                <v-img alt="" class="btn-view-ads" contain src="@/assets/ads_mail_2recruit.png"
                                    max-width="42" max-height="42" style="cursor:pointer;margin-right:20px;margin-top:-6px"
                                    transition="scale-transition" title="Annunci Selezionati" />

                                <v-img alt="" class="btn-confirm-mail" contain src="@/assets/btn_confirm3_funads.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupads" style="display:none">

            <template>
                <modal name="popupAds" :clickToClose="false" :width="300" :height="500">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Annunci

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="height:358px!important;width:100%!important;overflow-y:auto!important">

                                <table style="width:100%">
                                    <thead>
                                        <tr>
                                            <th style="width:10%;padding-left:7px">&nbsp;</th>
                                            <th style="width:25%">Codice</th>
                                            <th style="width:65%">Annuncio</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="v_ads in listAds" :key="v_ads.number_ads">
                                            <td style="padding-top:5px;padding-left:7px;vertical-align:top!important">
                                                <input type='checkbox' class='val_status_ads' :value="v_ads.number_ads" />
                                            </td>
                                            <td style="padding-top:5px;vertical-align:top!important">{{ v_ads.number_ads
                                            }}</td>
                                            <td style="padding-top:5px;vertical-align:top!important">{{ v_ads.title }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>


                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-ads" contain src="@/assets/btn_cancel3_funads.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:20px"
                                    transition="scale-transition" title="Annulla" />

                                <v-img alt="" class="btn-confirm-ads" contain src="@/assets/btn_confirm3_funads.png"
                                    max-width="32" max-height="32" style="cursor:pointer" transition="scale-transition"
                                    title="Conferma" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-img alt="Close Logo" class="  " contain src="@/assets/btn_cancel3_funads.png"
                                        max-width="30" max-height="30" transition="scale-transition"
                                        style="margin-right:20px!important" />
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">



                                        <v-img alt="" src="@/assets/aziende-jobmule.png" max-width="24" max-height="24" />

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>





                                    </v-list-item>
                                </v-card>
                            </v-flex>

                            <div style="min-height:170px;height:170px">&nbsp;</div>


                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

    </div>
</template>

<script>
import apicandidate from "../utils/candidate/apicandidate";
import apicategorie from "../utils/categorie/apicategorie";
import apinewsletter from "../utils/newsletter/apinewsletter";

import {
    bus
} from "../main";
import router from ".././router";
/* import $ from 'jquery'; */

// import Vue from "vue";
// import VModal from 'vue-js-modal';


export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/

        // Vue.use(VModal, {
        //     dialog: true
        // });

        this.setUrlDomainGlobal();


        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        var pointerVue = this;

        window.curAzienda = "-1";
        window.curSrcText = "";
        window.aziendeList = "";

        window.curProd = "Jobmule";

        $(document).ready(
            function () {

                $("body").off();

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            // btn-view-ads
            $('body').on('click', '.btn-view-ads', function () {

                console.log(("EVENTO CLICK BTN_VIEW_ADS"));

                tinymce.remove();

                pointerVue.$modal.hide('popupMail');

                pointerVue.returnToAds();

            });

            $('body').on('click', '.btn-cancel-ads', function () {

                console.log(("EVENTO CLICK BTN_CANCEL_ADS"));

                pointerVue.$modal.hide('popupAds');

            });


            $('body').on('click', '.btn-confirm-ads', function () {

                console.log(("EVENTO CLICK BTN_CONFIRM_ADS"));

                var tot = 0;
                var ids = "";

                $(".val_status_ads").each(function (index) {

                    console.log(index);

                    if ($(this).prop("checked")) {

                        tot = tot + 1;

                        if (ids != "") {
                            ids = ids + ",";
                        }
                        ids = ids + $(this).val();
                    }

                });

                console.log("TOT: ", tot);
                console.log("ANN SEL: ", ids);

                if (tot > 0) {

                    pointerVue.idAdsSel = ids;

                    pointerVue.$modal.hide('popupAds');

                    pointerVue.loadPreview();

                }
                else {

                    pointerVue.$swal({
                        icon: "error",
                        text: "Selezionare degli annunci",
                        showConfirmButton: false,
                        timer: 2000
                    });

                }



            });



            $('body').on('click', '.btn-confirm-mail', function () {

                console.log(("EVENTO CLICK BTN_MAIL"));

                pointerVue.sendMail();

            });


            $('body').on('click', '.btn-cancel-mail', function () {

                console.log(("EVENTO CLICK BTN_CANCEL_MAIL"));

                tinymce.remove();

                pointerVue.$modal.hide('popupMail');

            });



            $('body').on('click', '.selaggiorna', function () {

                console.log(("EVENTO CLICK SELAGGIORNA"));

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();

            });



            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });


            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });


            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");

                }

                pointerVue.hideShowButtonsSelRow();


            });

            $('body').on('click', '.btn-cancel-history', function (event) {
                console.log(event);

                pointerVue.$modal.hide('popupHistory');

            });

        });

        setTimeout(() => {
            this.initFilter();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);

            var ids = "";

            switch (data) {

                case "btn_delete":


                    this.deleteRow();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editCategoria/0"
                    });
                    break;

                case "btn_update_status":

                    this.sheetChangeStatus = true;

                    break;


                case "btn_history_cust":

                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });


                    console.log("IDS SEL:", ids);

                    window.$cookies.set("sel_id_cand_hist_cust", ids, "9y");

                    router.push({
                        path: "/storicoClientiProposti"
                    });


                    break;


                case "btn_edit":

                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editCategoria/" + ids
                    });




                    break;

                case "btn_back":
                    router.push({
                        path: "/filterusers"
                    });
                    break;

                case "btn_send_invite":


                    this.sendInvite();


                    break;

                default:
                    break;

            }
        });

        // this.setupButtons();

        this.setupButtonsGrid();

        //wait loading
        /* var v_token = window.$cookies.get("token");
        var v_company = window.$cookies.get("sel_filter_azienda_utenti"); */
        window.start = 0;
        //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

        // console.log("HOSTNAME: " + $(location).attr('hostname'));
        // console.log("PROTOCOL: " + $(location).attr('protocol'));
        //var pointerVue = this;

        /*         $.ajax({
                    url: pointerVue.getUrlDomain("getUtenti") + '?username=' + v_token + "&company=" + v_company,
                    data: {

                        currDay: window.currDay,
                        currDayTo: window.currDayTo,
                        curStatus: window.curStatus,
                        curPiano: window.curPiano,
                        start: window.start,
                        length: 30,

                    },
                    success: function (dati) {

                        window.dataset = dati.data;
                        window.loading = 0;
                        window.start = +30;
                        pointerVue.setupGrid();

                    }
                }); */

        /*  window.loading = 0;
         window.start = +30; */
        pointerVue.setupGrid();

        this.hideShowButtons(false);

    },
    data: () => ({

        aziende: [],

        sheetAziende: false,
        search: "",

        idAdsSel: "",
        mailToAds: "",

        listAds: [],


        textSearch: "",

        row_selected: null,


        sheetChangeStatus: false,
        sheetFilterStatus: false,

        customers_history: [],


        allStatus: [],
        allFilterStatus: [],

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),


    computed: {

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    methods: {

        setUrlDomainGlobal: function () {

            let result = "";

            if (window.siteForTest == "") {

                if (location.hostname == "localhost") {
                    result = location.protocol + "//emtool.local/";
                }

                if (location.hostname != "localhost") {

                    result = location.protocol + "//" + location.hostname + "/";

                }

            }
            else {

                result = window.siteForTest;

            }



            window.urlSiteCareers = result;

        },



        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curAzienda = azienda.companyid;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        sendMail: async function () {

            var that = this;

            var txt_body = tinymce.activeEditor.getContent();
            var mail_to = that.mailToAds;

            that.$modal.hide('popupMail');

            console.log("MAIL BODY: ", txt_body);
            console.log("MAIL ADDRESS TO: ", mail_to);

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("TOKEN:", v_token);
            console.log("ID SEL:", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.sendMail(
                v_token,
                ids,
                txt_body,
                mail_to
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from sendMail", res);

                that.$swal({
                    icon: "success",
                    text: "Mail inviata correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();


                    window.table.ajax.reload();

                    that.hideShowButtons(false);


                }, 200);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );





        },


        loadPreview: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("TOKEN:", v_token);
            console.log("ID SEL:", ids);

            console.log("ID ADS SEL:", that.idAdsSel);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.prevMail(
                v_token,
                ids,
                that.idAdsSel
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from prevMail", res);

                that.mailToAds = res.data.MailTo;


                $(".contpopupmail").show();

                that.$modal.show('popupMail');


                setTimeout(() => {

                    tinymce.init({
                        selector: '#sec_body',  // change this value according to your HTML

                        plugin: 'a_tinymce_plugin',
                        height: "370",
                        mode: "textareas",
                        entity_encoding: "raw",
                        add_unload_trigger: false,
                        remove_linebreaks: false,
                        apply_source_formatting: false,
                        force_br_newlines: true,
                        force_p_newlines: false,
                        forced_root_block: '', // Needed for 3.x

                        a_plugin_option: true,
                        a_configuration_option: 400

                    });

                    setTimeout(() => {
                        tinymce.activeEditor.setContent(res.data.TxtPrevMail);

                    }, 700);

                }, 500);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        returnToAds: async function () {

            var that = this;

            console.log("ID ADS SEL: ", that.idAdsSel);

            window.AdsSelId = that.idAdsSel;

            var v_token = window.$cookies.get("token");

            that.idAdsSel = ""; // reset id ads selezionati

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.getAds(
                v_token,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAds", res);

                that.listAds = res.data.Ads;

                setTimeout(() => {

                    $(".contpopupads").show();

                    that.$modal.show('popupAds');

                    console.log("ID ADS SEL 2: ", window.AdsSelId);

                    setTimeout(() => {

                        console.log("ID ADS SEL 3: ", window.AdsSelId);

                        // ripristino i checkbox già selezionati

                        var elements = window.AdsSelId.split(",");

                        console.log("ELEMENTS: ", elements);


                        $(".val_status_ads").each(function (index) {

                            console.log(index);

                            console.log("VAL: ", $(this).val());

                            for (var x = 0; x <= elements.length - 1; x++) {

                                if ($(this).val() == elements[x]) {

                                    console.log("ok");

                                    $(this).prop("checked", true);

                                }
                                else {

                                    console.log("no");

                                }

                            }



                        });





                    }, 500);





                }, 300);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        sendInvite: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            that.idAdsSel = ""; // reset id ads selezionati

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.getAds(
                v_token,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAds", res);

                that.listAds = res.data.Ads;

                setTimeout(() => {

                    $(".contpopupads").show();

                    that.$modal.show('popupAds');

                }, 300);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },



        sendInviteOld: async function () {

            var that = this;

            $(".contpopupads").show();

            that.$modal.show('popupAds');


        },

        previewMail: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");


            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("TOKEN:", v_token);
            console.log("ID SEL:", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.prevMail(
                v_token,
                ids,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from prevMail", res);


                $(".contpopupmail").show();

                that.$modal.show('popupMail');


                setTimeout(() => {

                    tinymce.init({
                        selector: '#sec_body',  // change this value according to your HTML

                        plugin: 'a_tinymce_plugin',
                        height: "370",
                        mode: "textareas",
                        entity_encoding: "raw",
                        add_unload_trigger: false,
                        remove_linebreaks: false,
                        apply_source_formatting: false,
                        force_br_newlines: true,
                        force_p_newlines: false,
                        forced_root_block: '', // Needed for 3.x

                        a_plugin_option: true,
                        a_configuration_option: 400

                    });

                    setTimeout(() => {
                        tinymce.activeEditor.setContent(res.data.TxtPrevMail);

                    }, 700);

                }, 500);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },

        previewMailOld: async function () {

            var that = this;

            $(".contpopupmail").show();

            that.$modal.show('popupMail');


            setTimeout(() => {

                tinymce.init({
                    selector: '#sec_body',  // change this value according to your HTML

                    plugin: 'a_tinymce_plugin',
                    height: "370",
                    mode: "textareas",
                    entity_encoding: "raw",
                    add_unload_trigger: false,
                    remove_linebreaks: false,
                    apply_source_formatting: false,
                    force_br_newlines: true,
                    force_p_newlines: false,
                    forced_root_block: '', // Needed for 3.x

                    a_plugin_option: true,
                    a_configuration_option: 400

                });

                // setTimeout(() => {
                //     tinymce.activeEditor.setContent("prova");

                // }, 700);

            }, 500);





        },


        historyCustomer: async function () {


            var that = this;

            var v_token = window.$cookies.get("token");


            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("TOKEN:", v_token);
            console.log("ID SEL:", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.customersHistory(
                v_token,
                ids,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from customersHistory", res);

                if (res.data.Result == "OK") {


                    that.customers_history = res.data.Customers;

                    console.log("ok")

                    setTimeout(() => {

                        $(".contpopuphistory").show();

                        that.$modal.show('popupHistory');

                        console.log("ok2");


                    }, 300);



                }
                else {


                    that.$swal({
                        icon: "error",
                        text: res.data.Errore,
                        showConfirmButton: false,
                        timer: 2000
                    });


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        initFilter: async function () {


            var that = this;

            var v_token = window.$cookies.get("token");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicategorie.initFilter(
                v_token,
                "Jobmule"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initFilter", res);

                that.aziende = res.data.company;

                // that.aziende.push({
                //     companyid: "",
                //     fld_name: ""
                // });


                window.aziendeList = res.data.company_list;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        changeFilterStatus: async function (status) {

            console.log(status);

            this.sheetFilterStatus = false;

            window.curStatus = status;

            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },


        changeStatus: async function (status) {

            console.log(status);

            this.sheetChangeStatus = false;

            var that = this;

            var v_token = window.$cookies.get("token");


            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("TOKEN:", v_token);
            console.log("VAL STATUS:", status);
            console.log("ID SEL:", ids);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicandidate.updateMultiStatus(
                v_token,
                ids,
                status
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateMultiStatus", res);

                that.$swal({
                    icon: "success",
                    text: "Stati aggiornati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();


                    window.table.ajax.reload();


                    that.hideShowButtons(false);


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },



        hideShowButtonsSelRow: function () {

            var tot = 0;
            var has_cust = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    has_cust = $(this).attr("has-cust");

                }


            });

            console.log("HAS_CUST: ", has_cust);

            setTimeout(() => {

                var pulsantis = [];

                if (tot > 0) {


                    if (window.urlSiteCareers == "https://funads.eu/") {

                        pulsantis.push({
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete-funads.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30
                        });

                    }
                    else {

                        pulsantis.push({
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/delete-careers2.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina",
                            width: 30
                        });


                    }




                }





                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            var pointerVue = this;




            var filtri = ``;

            const date = new Date().toJSON().slice(0, 10)


            $(document).ready(function () {

                var v_token = window.$cookies.get("token");

                window.columns = [{
                    data: 'sel_row',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },

                {
                    data: 'DT_RowIndex',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },

                // {
                //     data: 'created_at',
                //     orderable: false,
                //     name: 'created_at',
                //     width: '7%'
                // },

                {
                    data: 'created_at',
                    orderable: true,
                    name: 'created_at',
                    width: '3%',

                    render: function (data, row) {
                        console.log(data, row);

                        var date = "";
                        try {

                            date = data.substring(0, 10);
                        } catch (error) {
                            console.log(error);
                        }

                        var hour = "";
                        try {

                            hour = data.split(" ")[1].slice(0, 5);
                        } catch (error) {
                            console.log(error);
                        }

                        var html = "<div class='cl-div-hid'>" + data + "</div><div class='font-row-grid-datetime'><div class='cl-time'>" + hour + "</div><div class='cl-date'>" + date + "</div></div>";

                        return html;
                    }


                },





                {
                    data: 'email',
                    orderable: false,
                    name: 'email',
                    width: '9%'
                },




                ];



                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    /*  pageSize: 30, */
                    "sDom": "lfrti",

                    data: window.dataset,



                    ajax: {
                        url: pointerVue.getUrlDomain("CareersJobmuleNewsletterApi") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curSrcText = window.curSrcText;
                            valori.curAzienda = window.curAzienda;

                            valori.curProd = window.curProd;

                            valori.portale = window.urlSiteCareers;


                            window.valori = valori;
                            return valori;

                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(function () {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);


                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        // $('.p_valore').number(true, 1, ',', '.');
                        // $('.p_totale').number(true, 2, ',', '.');
                    }

                });




                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();
                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("CareersJobmuleNewsletterApi") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Newsletter</div>

                    ` + filtri + `

                </div>
                
            `);


                $('body').on('click', '.selazienda', function () {

                    console.log(("EVENTO CLICK SELAZIENDA"));

                    pointerVue.sheetAziende = true;

                });


                $(".selstato").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.sheetFilterStatus = true;


                });

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });

                $('body').on('click', '.btn-confirm-search', function () {

                    console.log("ok");

                    window.curSrcText = pointerVue.textSearch;

                    pointerVue.$modal.hide('popupSearch');

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();


                });

                $('body').on('click', '.btn-cancel-search', function () {

                    console.log("ok");

                    pointerVue.$modal.hide('popupSearch');

                });




                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });

                $('body').on('click', '.cont_ads_categorie tr', function () {


                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            this.row_selected = row_data;


                            console.log("ROW DATA: ", row_data);


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");


                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");


                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);


                            console.log("FIND VAL_STATUS: ", $(this).find(".val_status"));

                            var find_check = $(this).find(".val_status");
                            if (find_check.is(":checked")) {
                                console.log("SELEZIONATO");

                            }

                            if (!find_check.is(":checked")) {
                                console.log("DESELEZIONATO");

                            }


                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);




                });

                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                     

                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-funads.png",
                            // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30,
                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                            class: "inverted",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            title: "Modifica"

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                            class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            title: "Elimina"

                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     image: "https://app.emtool.eu/public/_lib/img/back.png",
                        //     link: "/confOrariChoice",
                        //     class: "inverted",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro"
                        // },


                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


        deleteRow: async function () {

            var that = this;

            // var v_token = window.$cookies.get("token");

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_funads.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_funads.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);


                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apinewsletter.deleteNewsletter(
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteNewsletter", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();



                                window.table.ajax.reload();

                                that.hideShowButtons(false);


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cont-table-ads-categorie {
    overflow-x: hidden;
    overflow-y: hidden;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #d00914;

}

.pay a {
    color: #d00914;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #d00914;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}


.cont-table-ads-categorie .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}


.cont_ads_categorie #myTable {
    margin-right: 3px;
    width: 350px !important;
    margin-top: 69px;
}

.cont_ads_categorie table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_ads_categorie table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-bottom: 9px !important;
}

.cont_ads_categorie th {
    padding-left: 5px !important;
}

.cont_ads_categorie ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_ads_categorie .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_ads_categorie #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    margin-top: 13px !important;
    background-color: white;
    border-color: #d00914;
}

.cont_ads_categorie div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}


.cont_ads_categorie #myTable_wrapper {
    min-width: initial;
    min-width: 450px;
    max-width: 450px;
    width: 450px;
    font-size: 11px;
    color: black;
    font-weight: bold;

}

.cont-table-ads-categorie .text_title {
    background-color: white;
    color: black;
    font-weight: bold !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 0px !important;
    font-size: 10px !important;
    min-width: 50px !important
}

.cont_ads_categorie .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    min-width: 95px !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
}

.cont_ads_categorie .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}


.cont_ads_categorie .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_ads_categorie .checkall {
    width: 13px;
    height: 13px;
}


.cont_ads_categorie .val_status {
    width: 10px !important;
}


.cont_ads_categorie .selaggiorna {
    max-width: 22px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_ads_categorie .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_ads_categorie .selstato {
    max-width: 22px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.closeStatusSheet {
    position: absolute;
    right: 0;
}

.font-row-grid-datetime {
    font-size: 10px !important;
    width: 100px !important;
}

.font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 100px !important;
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cl-div-hid {
    display: none;
}

.cont_ads_categorie input[type='search'] {
    visibility: hidden;
}

.cont_ads_categorie .selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemAzienda {
    margin-left: 15px;
}


.cl-div-hid {
    display: none;
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.font-row-grid-datetime {
    font-size: 10px !important;
    width: 100px !important;
}

.font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 100px !important;
}


@media screen and (max-width: 960px) {

    .cont_ads_categorie .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_ads_categorie #myTable {
        margin-top: 57px;

    }

    .cont_ads_categorie #myTable_filter {
        top: 56px;
        position: fixed;

    }

}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }



    .cont_ads_categorie #myTable {
        max-width: 95vw !important;
        margin-right: 0px;
        min-width: 95vw !important;
        position: relative;
        width: 95vw !important;

    }

    .cont_ads_categorie #myTable_filter {
        top: 44px;
        position: fixed;

    }

    .cont_ads_categorie .table-responsive {
        padding: 0px;
        min-width: 95vw !important;
        max-width: 95vw !important;
        left: 0px;
        position: fixed;

    }


    .cont_ads_categorie #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
        margin-top: 14px !important;
    }

    .cont_ads_categorie .cl_num {
        margin-left: -2px !important;
    }


    .cont_ads_categorie .cl_checkall {
        padding-left: 1px;
        padding-top: 2px;
    }

    .font-row-grid-datetime {
        font-size: 10px !important;
        width: 100px !important;
        display: inline-table !important;
    }

    .cl-txt-minor {
        display: inline;
    }

}
</style>
