<template>
    <v-container class="w-container v-overflow conf_settings_portale" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle"
                    style="
                                                                                                                                                background-color: white;
                                                                                                                                                color: black;
                                                                                                                                                font-weight: bold;
                                                                                                                                                padding: 4px;
                                                                                                                                                font-size: 10px;
                                                                                                                                                min-width:100vw;
                                                                                                                                                border-color: #d00914;
                                                                                                                                                z-index:3">
                    {{
                        titolo }}
                </div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>




            <v-row>

                <v-col cols="12" md="12">

                    <v-textarea v-model="impostazioni.txt_azi_dx_footer" label="Testo Azienda Footer Destra"
                        id="txt_azi_dx_footer">
                    </v-textarea>

                </v-col>

            </v-row>


            <v-row>

                <v-col cols="12" md="12">

                    <v-textarea v-model="impostazioni.azienda_overview" label="Presentazione Azienda" id="azienda_overview">
                    </v-textarea>

                </v-col>

            </v-row>


        </v-form>

    </v-container>
</template>

<script>

import apisettings from "../utils/settings/apisettings";
import apicareers from "../utils/careers/apicareers";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {


        /*eslint-disable no-undef*/

        this.setUrlDomainGlobal();


        var pointerVue = this;

        this.setupButtons();

        setTimeout(() => {

            var oggDrop = $('.dropify').dropify({
                messages: {
                    default: 'Drag',
                    replace: /* s */ '',
                    remove: 'Rimuovi',
                    error: 'File troppo grande'
                }
            });

            $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
            $(".dropify-render").append("<img>");

            //console.log(oggDrop);

            oggDrop.on('dropify.afterClear', function (event, element) {

                console.log(event, element);

                pointerVue.fileLogoRemove = 1;


            });


            var oggDrop1 = $('.dropify1').dropify({
                messages: {
                    default: 'Drag',
                    replace: /* s */ '',
                    remove: 'Rimuovi',
                    error: 'File troppo grande'
                }
            });

            $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
            $(".dropify-render").append("<img>");

            //console.log(oggDrop);

            oggDrop1.on('dropify.afterClear', function (event, element) {

                console.log(event, element);

                pointerVue.fileHeaderRemove = 1;

            });


            var oggDrop2 = $('.dropify2').dropify({
                messages: {
                    default: 'Drag',
                    replace: /* s */ '',
                    remove: 'Rimuovi',
                    error: 'File troppo grande'
                }
            });

            $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
            $(".dropify-render").append("<img>");

            //console.log(oggDrop);

            oggDrop2.on('dropify.afterClear', function (event, element) {

                console.log(event, element);

                pointerVue.fileParallaxRemove = 1;

            });



        }, 100);

        setTimeout(() => {

            this.getSettings();

        }, 100);




        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/pagamenti"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    destroyed() {
        bus.$off("bottomIconClickEvent");
    },


    computed: {

        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },

    data: () => ({

        fileLogoRemove: 0,
        fileHeaderRemove: 0,
        fileParallaxRemove: 0,

        fieldDis: false,

        showRow: true,
        showRow2: true,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Azienda",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni: { url_rss: "", txt_azi_dx_footer: "", txt_powered_by: "", txt_privacy: "", azienda_overview: "" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,

    }),

    methods: {


        setUrlDomainGlobal: function () {

            let result = "";

            if (window.siteForTest == "") {

                if (location.hostname == "localhost") {
                    result = location.protocol + "//emtool.local/";
                }

                if (location.hostname != "localhost") {

                    result = location.protocol + "//" + location.hostname + "/";

                }

            }
            else {

                result = window.siteForTest;

            }



            window.urlSiteCareers = result;

        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [];

                if (window.urlSiteCareers == "https://funads.eu/") {

                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/save_update_data-funads.png",
                        title: "Salva",
                        width: 30,
                    });

                }
                else {

                    pulsantis.push({
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/save_update_data-careers2.png",
                        title: "Salva",
                        width: 30,
                    });

                }



                // var pulsantis = [{
                //     text: "Salva",
                //     icon: "mdi-content-save",
                //     link: "/timesheet",
                //     id: "btn_save",
                //     disabled: false,
                //     image: "https://app.emtool.eu/public/_lib/img/save_update_data-funads.png",
                //     title: "Salva",
                //     width: 30,
                // },

                // ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate: function () {

            var that = this;

            var errore = "";



            if (errore == "") {

                console.log("ok");

                that.saveData();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }



        },


        getSettings: async function () {

            var that = this;

            //  var v_token = window.$cookies.get("token");

            var response = await apicareers.getSettings(
                window.urlSiteCareers
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getSettings", res);

                that.impostazioni = res.data.Result;



                var allegato_salvato = res.data.Result.file_logo;

                if (allegato_salvato) {


                    if (allegato_salvato != "null") {

                        var txt_image = this.getUrlDomain("public/careers/" + allegato_salvato);

                        console.log("LOGO: ", txt_image);

                        setTimeout(() => {

                            $(".rowFileLogo").find(".dropify-render").append("<img src='" + txt_image + "'>");
                            $(".rowFileLogo").find(".dropify-wrapper").addClass("has-preview");
                            $(".rowFileLogo").find(".dropify-preview").show("slow");

                        }, 100);



                    }




                }






                var allegato_salvato2 = res.data.Result.file_img_sfondo_header;

                if (allegato_salvato2) {


                    if (allegato_salvato2 != "null") {

                        var txt_image2 = this.getUrlDomain("public/careers/" + allegato_salvato2);

                        console.log("HEADER: ", txt_image2);

                        setTimeout(() => {

                            $(".rowFileHeader").find(".dropify-render").append("<img src='" + txt_image2 + "'>");
                            $(".rowFileHeader").find(".dropify-wrapper").addClass("has-preview");
                            $(".rowFileHeader").find(".dropify-preview").show("slow");

                        }, 100);


                    }


                }


                var allegato_salvato3 = res.data.Result.file_img_sfondo_parallax;

                if (allegato_salvato3) {

                    if (allegato_salvato3 != "null") {


                        var txt_image3 = this.getUrlDomain("public/careers/" + allegato_salvato3);

                        console.log("HEADER: ", txt_image3);

                        setTimeout(() => {

                            $(".rowFileParallax").find(".dropify-render").append("<img src='" + txt_image3 + "'>");
                            $(".rowFileParallax").find(".dropify-wrapper").addClass("has-preview");
                            $(".rowFileParallax").find(".dropify-preview").show("slow");



                        }, 100);



                    }


                }











            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            console.log("TOKEN: ", v_token);
            console.log("SETTINGS: ", that.impostazioni);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apicareers.saveCompany(
                v_token,
                that.impostazioni,
                window.urlSiteCareers
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from saveCompany", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.$root.$children[0].logout();

                }, 2000);


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        syncConfInvioMail: async function () {

            // var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisettings.getSettings(
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getSettings", res);

                try {

                    that.impostazioni = res.data.settings;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.conf_settings_portale .v-label {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}


.conf_settings_portale .v-input {
    font-size: 11px !important;
    padding: 10px !important;
    color: black !important;
    font-weight: bold !important;
}

.conf_settings_portale ion-select {
    font-size: 11px !important;
    color: black !important;
    font-weight: bold !important;
}



#conf_settings_alertmsg .v-label {
    font-size: 1em;
}

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .conf_settings_portale .dropify-wrapper {
        height: 236px !important;
        width: 341px !important;
        margin-bottom: 20px;
        margin-left: 10px;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}
</style>
