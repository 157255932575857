import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIProfile {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "public/api/smartbook/" + nome_metodo;


  }


/*   constructor ({ url }) {
      console.log ( url );
      httpOk = axios.create({
      baseURL: url,
       headers: { 'Content-Type': 'application/json' }
    })
  } */


  getProfile ( username , tokensystem    )
  {
    //var resource = "/getWorkerInfo" ;
    let formData = new FormData();
    formData.append(  "user" ,  username   );
    formData.append(  "password" ,    tokensystem   );


    let payload = "user="+username + "&password=" +  tokensystem + `&timestamp=${new Date().getTime()}` ;

    //const params = new url.URLSearchParams(payload);

    //alert("before post");
      //return httpOk.post(  resource, formData   ) ;
     // alert(username+ " toke:" + tokensystem);
      //return axios.post( `https://services.ebadge.it/public/api/getWorkerInfo?${payload}` , formData ,{  'Access-Control-Allow-Origin': '*','Content-Type': 'multipart/form-data' }   ) ;

      //return axios.post( `http://services.local/public/api/getWorkerInfoWithoutRes?${payload}` , formData ,{  'Access-Control-Allow-Origin': '*','Content-Type': 'multipart/form-data' }   ) ;

      //return axios.post( `https://services.ebadge.it/public/api/getWorkerInfoWithoutRes?${payload}` , formData ,{  'Access-Control-Allow-Origin': '*','Content-Type': 'multipart/form-data' }   ) ;

      return axios.post( `https://services.ebadge.it/public/api/getWorkerInfoWithoutResAlertMsg?${payload}` , formData ,{  'Access-Control-Allow-Origin': '*','Content-Type': 'multipart/form-data' }   ) ;

  }



  setProfile ( username , tokensystem , profile   )
  {
   // var resource = "/setWorkerInfo" ;
    let formData = new FormData();
    formData.append(  "user" ,  username   );
    formData.append(  "password" ,    tokensystem   );
    formData.append(  "date" ,    new Date().getTime()   );

    for ( var key in profile ) {
       // console.log (key,profile[key] );
        formData.append(key, profile[key]);
    }
    //console.log ( formData );
    return axios.post( `https://services.ebadge.it/public/api/setWorkerInfo` , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

    //return axios.post( `https://services.ebadge.it/public/api/setWorkerInfoWithoutRes` , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

    //return axios.post( `http://services.local/public/api/setWorkerInfoWithoutRes` , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  //    return httpOk.post(resource, formData   ) ;
  }



  savePassword ( username , tokensystem , profile   )
  {
   // var resource = "/setWorkerInfo" ;
    let formData = new FormData();
    formData.append(  "user" ,  username   );
    formData.append(  "password" ,    tokensystem   );

    for ( var key in profile ) {
       // console.log (key,profile[key] );
        formData.append(key, profile[key]);
    }
    //console.log ( formData );
    return axios.post( `https://app.emtool.eu/public/api/emt/setWorkerPassword` , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  //    return httpOk.post(resource, formData   ) ;
  }



  getProfileUser( username , tokensystem  ,id  )
  {
    let formData = new FormData();
    formData.append(  "user" ,  username   );
    formData.append(  "password" ,    tokensystem   );
    formData.append(  "id" ,  id   );


    let payload = "user="+username + "&password=" +  tokensystem + "&id=" +  id + `&timestamp=${new Date().getTime()}` ;

    //const params = new url.URLSearchParams(payload);

    //alert("before post");
      //return httpOk.post(  resource, formData   ) ;
     // alert(username+ " toke:" + tokensystem);

     return axios.post( this.getUrlDomain("getProfileUser")  +  `?${payload}` , formData ,{  'Access-Control-Allow-Origin': '*','Content-Type': 'multipart/form-data' }   ) ;


  }



}

export default new APIProfile({
  url: "https://services.ebadge.it/public/api/"
})
