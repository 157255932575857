import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APISottocategorie {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async initInsert(user, v_prod) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_prod", v_prod);
   
        return axios.post(this.getUrlDomain("FunAdsSottocategorieInitInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertSottocategorie(sottocategoria, user) {

        let formData = new FormData();

        formData.append("username", user);
   
        for (var key in sottocategoria) {
            formData.append(key, sottocategoria[key]);
        }

        return axios.post(this.getUrlDomain("FunAdsSottocategorieInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async initEdit(id, user, v_prod) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);
        formData.append("v_prod", v_prod);
   
     
        return axios.post(this.getUrlDomain("FunAdsSottocategorieInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateSottocategorie(sottocategoria, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("v_id", id);
   
        for (var key in sottocategoria) {
            formData.append(key, sottocategoria[key]);
        }

        return axios.post(this.getUrlDomain("FunAdsSottocategorieUpdApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteSottocategorie(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
   

        return axios.post(this.getUrlDomain("FunAdsSottocategorieDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initFilter(user) {

        let formData = new FormData();

        formData.append("username", user);
   

        return axios.post(this.getUrlDomain("TwoRecruitProvinceInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }



}

export default new APISottocategorie ({
    url: "https://services.ebadge.it/public/api/"
})
