import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIPrivacy {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async initInsert(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("FunAdsPrivacyInitInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertPrivacy(companyid, user, file) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("companyid", companyid);
        formData.append("file", file);


        return axios.post(this.getUrlDomain("FunAdsPrivacyInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initEdit(id, user) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updatePrivacy(companyid, user, file, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("companyid", companyid);
        formData.append("file", file);
        formData.append("v_id", id);

        return axios.post(this.getUrlDomain("FunAdsPrivacyUpdApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deletePrivacy(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("FunAdsPrivacyDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initFilter(user) {

        let formData = new FormData();

        formData.append("username", user);


        return axios.post(this.getUrlDomain("FunAdsPrivacyInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

}

export default new APIPrivacy({
    url: "https://services.ebadge.it/public/api/"
})
